import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Dashboard.module.scss';
import { OrganisationContext, PackageContext, UserContext } from '../../../Context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import { Employee, CalendarEvent, CalendarSettings, HappinessLog } from '../../../Classes/Employee';
import { faFaceGrinBeam, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { EmploymentStatus, StatusColours } from '../../../Enums/EmploymentStatus';
import { EncryptValue } from '../../../Services/Crypto';
import { faCalendarAlt, faCalendarCheck, faChevronLeft, faChevronRight, faCloudDownloadAlt, faCogs, faDownload, faSitemap, faThList, faToolbox, faUserCheck, faUserFriends, faUsers, faAddressCard, faUserSlash, faAward, faLaptop, faTasks } from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js/auto';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale);
import PortalEmployeeScatterCalendar from '../Employees/Employee/ScatterCalendar/ScatterCalendar';
import { ProgressSection } from '../../../Classes/ProgressSection';
import { AccountProgressCheck } from '../../../Services/ProfileProgress';
import { HappinessOption } from '../../../Classes/User';
import UnassignedTitle from '../../../components/UnassignedTitle/UnassignedTitle';
import EventRequest from '../../../components/EventRequest/EventRequest';

const PortalDashboard: React.FC = () => {
  const { User, Language } = useContext(UserContext);
  const { Packages } = useContext(PackageContext);
  const { Organisation } = useContext(OrganisationContext);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [sections, setSections] = useState<ProgressSection[]>([] as ProgressSection[]);
  const [moods, setMoods] = useState<HappinessOption[]>([] as HappinessOption[]);

  const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const employeeColours: string[] = ["#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#db7093",
    "#eee8aa",
    "#b0e0e6",
    "#ff1493",
    "#ffa07a",
    "#ee82ee",
    "#7fffd4"];

  const Budget = (Type: "Monthly" | "Yearly" | "Weekly" | "Daily" | "Hourly") => {
    let cost: number = 0;
    switch (Type) {
      case "Yearly":
        Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map((e: Employee) => {
          cost += e.Business.CurrentEmployment.YearlyPay ? parseFloat(e.Business.CurrentEmployment.YearlyPay) : 0;
        })
        break;
      case "Monthly":
        Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map((e: Employee) => {
          cost += e.Business.CurrentEmployment.MonthlyPay ? parseFloat(e.Business.CurrentEmployment.MonthlyPay) : 0;
        })
        break;
      case "Weekly":
        Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map((e: Employee) => {
          cost += e.Business.CurrentEmployment.WeeklyPay ? parseFloat(e.Business.CurrentEmployment.WeeklyPay) : 0;
        })
        break;
      case "Daily":
        Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map((e: Employee) => {
          cost += e.Business.CurrentEmployment.DailyPay ? parseFloat(e.Business.CurrentEmployment.DailyPay) : 0;
        })
        break;
      case "Hourly":
        Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map((e: Employee) => {
          cost += e.Business.CurrentEmployment.HourlyPay ? parseFloat(e.Business.CurrentEmployment.HourlyPay) : 0;
        })
        break;
    }
    return new Intl.NumberFormat("en-GB").format(cost).includes(".") ? new Intl.NumberFormat("en-GB").format(cost) : new Intl.NumberFormat("en-GB").format(cost) + ".00";
  }

  const GetEmployeeDaysOffByStatus = (Status: EmploymentStatus) => {
    let count: number = 0;
    Organisation.Employees.filter(e => e.CalendarEvents && e.CalendarEvents.length).filter(e => e.CalendarEvents.filter(ce => ce.Type === Status).map((ce => {
      JSON.parse(ce.Settings).map((s: CalendarSettings) => {
        if (new Date(s.Day).getFullYear() === year)
          count += e.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
      })
    })))
    return count;
  }

  const GetProgress = (Emp: Employee) => {
    let total: number = 0;
    let current: number = 0;
    // Part 1
    total++;
    if (Emp.Personal.Forename)
      current++;
    total++;
    if (Emp.Personal.Surname)
      current++;
    total++;
    if (Emp.Personal.Email)
      current++;
    total++;
    if (Emp.Personal.Phone)
      current++;

    // Part 2
    total++;
    if (Emp.Business.CurrentEmployment.StartDate)
      current++;
    total++;
    if (Emp.Business.StartDate)
      current++;
    total++;
    if (Emp.Business.Phone)
      current++;
    total++;
    if (Emp.Business.Email)
      current++;
    total++;
    if (Emp.Business.Department.ID)
      current++;
    // total++;
    // if (Emp.Business.Team.ID)
    //   current++;
    total++;
    if (Emp.Business.CurrentEmployment.EmploymentType)
      current++;

    // Part 3
    total++;
    if (Emp.Business.CurrentEmployment.ContractedHours)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.WorkDays)
      current++;

    // Part 4
    total++;
    if (Emp.Business.CurrentEmployment.JobTitle)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.Description)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.TaxCode)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.HourlyPay)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.DailyPay)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.WeeklyPay)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.MonthlyPay)
      current++;
    total++;
    if (Emp.Business.CurrentEmployment.YearlyPay)
      current++;

    // Part 5
    total++;
    if (Emp.Business.Probation.ProbationStartDate)
      current++;
    total++;
    if (Emp.Business.Probation.ProbationEndDate)
      current++;
    total++;
    if (Emp.Business.Probation.ProbationLength)
      current++;
    total++;
    if (Emp.Status)
      current++;
    total++;

    // Part 6
    if (Emp.Account && Emp.Account.Email)
      current++;

    return (100 / total) * current;
  }

  useEffect(() => {
    if (User.ID && Organisation.Employees && Packages.length) {
      setSections(AccountProgressCheck(User, Organisation, Packages));
      const options: HappinessOption[] = [{
        Icon: faQuestionCircle,
        Name: "Unassigned",
        Colour: "#ccc"
      }];

      User.HappinessOptionsData.map((option: HappinessOption) => {
        options.push(option);
      })

      setMoods(options);
    }
  }, [User, Organisation, Packages])

  const GetAccountProgress = () => {
    let total: number = sections.length;
    let current: number = sections.filter(s => s.isDone).length;
    return (100 / total) * current;
  }

  const GetHappinessValues = () => {
    let data: number[] = [];

    months.map((_m, i) => {
      if (i === month)
        moods.map((_h, ind) => {
          let total: number = 0;

          Organisation.Employees.filter((e) => e.Account.ID).map((e) => {
            total += ((e.Account.HappinessLog ? JSON.parse(e.Account.HappinessLog) : []) as HappinessLog[]).filter((l) => new Date(l.Date).getFullYear() === year && new Date(l.Date).getMonth() === i && l.Happiness === (ind + 1)).length
          })

          data.push(total);
        })
    })

    return data;
  }

  const GetLines = () => {
    let values: any[] = [];

    Organisation.Employees.filter((e) => e.Account.HappinessLog && !e.OffboardingComplete).map((e, eID) => {
      let logData: number[] = [] as number[];
      let prev: number = 0;

      GetLineLabels().map((t) => {
        let log: HappinessLog | undefined = (JSON.parse(e.Account.HappinessLog) as HappinessLog[]).find((l) => new Date(l.Date).toLocaleString() === t);

        if (log) {
          prev = log.Happiness;
        }

        logData.push(prev);
      })

      values.push({
        data: logData,
        borderColor: employeeColours[eID],
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 0,
        pointHoverRadius: 0,
        label: `${e.Personal.Forename} ${e.Personal.Surname}`
      });
    })

    return values;
  }

  const GetLineLabels = () => {
    let data: string[] = [];

    Organisation.Employees.filter((e) => e.Account && e.Account.HappinessLog && !e.OffboardingComplete).map((e) => {
      (JSON.parse(e.Account.HappinessLog) as HappinessLog[]).filter((l) => new Date(l.Date).getFullYear() === year && new Date(l.Date).getMonth() === month).map((l) => {
        data.push(new Date(l.Date).toLocaleString());
      })
    })

    return data;
  }

  return (<>
    {GetAccountProgress() === 100 ? <></> : <div className={styles.AccountProgress}>
      <div className={styles.AccountProgressInner}>
        <h1>Set up your PrimeHR Account</h1>
        <b>
          {sections.filter(s => !s.isDone).length} out of {sections.length} Tasks Remaining

          {sections.filter(s => s.isDone).length !== sections.length ? <span>
            Next Task: <i onClick={() => navigate(sections.filter(s => !s.isDone)[0].Route)}>{sections.filter(s => !s.isDone)[0].Title}</i>
          </span> : <span>
            <i className={styles.Green}>Completed!</i>
          </span>}
        </b>

        <p>{GetAccountProgress().toFixed(0)}% Complete</p>

        <div className={styles.Storage}>
          <div style={{ width: `${GetAccountProgress()}%` }} className={`${styles.StorageProgress} ${GetAccountProgress() >= 100 ? styles.Green : ""}`}></div>
        </div>
        <small>
          <Link to="/Portal/Account/Progress">View Progress</Link>
        </small>
      </div>
    </div>}

    <div className={styles.Apps}>
      <div className={styles.AppsBlock}>
        <Link to="/Portal/Storage" title={Language.Applications.DocumentStorage} className={styles.App}>
          <i>
            <FontAwesomeIcon icon={faDownload} />
          </i>
          <p>{Language.Applications.DocumentStorage}</p>
        </Link>
        <Link to="/Portal/Documents" title={Language.Applications.DocumentTemplates} className={styles.App}>
          <i>
            <FontAwesomeIcon icon={faCloudDownloadAlt} />
          </i>
          <p>{Language.Applications.DocumentTemplates}</p>
        </Link>
        <Link to="/Portal/Employees" title={Language.Applications.Employees} className={styles.App}>
          <i>
            <FontAwesomeIcon icon={faUserFriends} />
          </i>
          <p>{Language.Applications.Employees}</p>
        </Link>
        <Link to="/Portal/Company Calendar" title={Language.Applications.CompanyCalendar} className={styles.App}>
          <i>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </i>
          <p>{Language.Applications.CompanyCalendar}</p>
        </Link>
      </div>
      <div className={styles.AppsBlock}>
        <Link to="/Portal/Tools" className={styles.App} title={Language.Applications.ToolsWorkflows}>
          <i>
            <FontAwesomeIcon icon={faToolbox} />
          </i>
          <p>{Language.Applications.ToolsWorkflows}</p>
        </Link>
        <Link to="/Portal/Employees/Heirarchy" className={styles.App} title={Language.Applications.CompanyHierarchy}>
          <i>
            <FontAwesomeIcon icon={faSitemap} />
          </i>
          <p>{Language.Applications.CompanyHierarchy}</p>
        </Link>
        <Link to="/Portal/Employees/Departments" className={styles.App} title={Language.Applications.Departments}>
          <i>
            <FontAwesomeIcon icon={faThList} />
          </i>
          <p>{Language.Applications.Departments}</p>
        </Link>
        <Link to="/Portal/Employees/Teams" className={styles.App} title={Language.Applications.Teams}>
          <i>
            <FontAwesomeIcon icon={faUsers} />
          </i>
          <p>{Language.Applications.Teams}</p>
        </Link>
      </div>
      <div className={styles.AppsBlock}>
        <Link to="/Portal/Employees/References" className={styles.App} title={Language.Applications.ReferenceChecks}>
          <i>
            <FontAwesomeIcon icon={faUserCheck} />
          </i>
          <p>{Language.Applications.ReferenceChecks}</p>
        </Link>
        <Link to="/Portal/Employees/Leave Requests" className={styles.App} title={Language.Applications.LeaveRequests}>
          <i>
            <FontAwesomeIcon icon={faCalendarCheck} />
          </i>
          <p>{Language.Applications.LeaveRequests}</p>
        </Link>
        {User.HappinessOptionsData.length ? <Link to="/Portal/Employees/Mood Monitor" className={styles.App} title={Language.Applications.MoodMonitoring}>
          <i>
            <FontAwesomeIcon icon={faFaceGrinBeam} />
          </i>
          <p>{Language.Applications.MoodMonitoring}</p>
        </Link> : <></>}
        <Link to="/Portal/Asset%20Management" className={styles.App} title={Language.Applications.AssetManagement}>
          <i>
            <FontAwesomeIcon icon={faLaptop} />
          </i>
          <p>{Language.Applications.AssetManagement}</p>
        </Link>
      </div>
      <div className={styles.AppsBlock}>
        <Link to="/Portal/Tools/Workflows/Onboarding" className={styles.App} title={Language.Applications.OnboardingWorkflow}>
          <i>
            <FontAwesomeIcon icon={faAddressCard} />
          </i>
          <p>{Language.Applications.OnboardingWorkflow}</p>
        </Link>
        <Link to="/Portal/Tools/Workflows/Offboarding" className={styles.App} title={Language.Applications.OffboardingWorkflow}>
          <i>
            <FontAwesomeIcon icon={faUserSlash} />
          </i>
          <p>{Language.Applications.OffboardingWorkflow}</p>
        </Link>
        <Link to="/Portal/Benefits" className={styles.App} title={Language.Applications.BenefitsPerks}>
          <i>
            <FontAwesomeIcon icon={faAward} />
          </i>
          <p>{Language.Applications.BenefitsPerks}</p>
        </Link>
        <Link to="/Portal/Employees/Task Manager" className={styles.App} title={Language.Applications.TaskManager}>
          <i>
            <FontAwesomeIcon icon={faTasks} />
          </i>
          <p>{Language.Applications.TaskManager}</p>
        </Link>
      </div>
    </div>

    {Organisation && Organisation.Employees ? <div className={styles.Updates}>
      {User.HappinessOptionsData.length ? <>
        <div className={`${styles.UpdatesItem} ${styles.TwoThirds}`}>
          <h1>
            <span>{Language.Dashboard.OverallHappiness}</span>
            <button onClick={() => { setYear(month === 0 ? year - 1 : year); setMonth(month === 0 ? 11 : month - 1) }}>
              <i>
                <FontAwesomeIcon icon={faChevronLeft} />
              </i>
            </button>
            <label>{months[month]}, {year}</label>
            <button onClick={() => { setYear(month === 11 ? year + 1 : year); setMonth(month === 11 ? 0 : month + 1) }}>
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </button>
            <Link to={`/Portal/Employees/Mood Monitor`}>
              <i>
                <FontAwesomeIcon icon={faCogs} />
              </i>
            </Link>
          </h1>
          <div className={styles.UpdatesItemInner}>
            <div className={styles.Chart}>
              <div className={styles.ChartInner}>
                {Organisation.Employees.length ? <Line options={{
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  scales: {
                    y: {
                      ticks: {
                        callback: (value) => {
                          return moods.find((_h, i) => i === value)?.Name;
                        }
                      }
                    }
                  }
                }} data={{
                  labels: GetLineLabels(),
                  datasets: GetLines()
                }} width={100} height={35} /> : <></>}
              </div>
              <div className={styles.ChartInfo}>
                <ul>
                  {
                    Organisation.Employees.filter((e) => e.ID && !e.OffboardingComplete && e.OnboardingComplete).map((e, i) => {
                      return <li><span style={{ opacity: e.OnboardingComplete ? .5 : 1, backgroundColor: employeeColours[i] }}></span><label>{e.Personal.Forename} {e.Personal.Surname}</label></li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.UpdatesItem} ${styles.Third}`}>
          <h1>
            <span>{Language.Dashboard.EmployeeHappiness}</span>
            <button onClick={() => { setYear(month === 0 ? year - 1 : year); setMonth(month === 0 ? 11 : month - 1) }}>
              <i>
                <FontAwesomeIcon icon={faChevronLeft} />
              </i>
            </button>
            <label>{months[month]}, {year}</label>
            <button onClick={() => { setYear(month === 11 ? year + 1 : year); setMonth(month === 11 ? 0 : month + 1) }}>
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </button>
            <Link to={`/Portal/Employees/Mood Monitor`}>
              <i>
                <FontAwesomeIcon icon={faCogs} />
              </i>
            </Link>
          </h1>
          <div className={styles.UpdatesItemInner}>
            <div className={styles.Chart}>
              <div className={styles.ChartInner}>
                {Organisation.Employees.length ? <Doughnut options={{
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }} data={{
                  labels: User.HappinessOptionsData.map((opt: HappinessOption) => opt.Name),
                  datasets: [{
                    data: GetHappinessValues(),
                    backgroundColor: User.HappinessOptionsData.map((opt: HappinessOption) => opt.Colour),
                    borderColor: ["white"],
                    borderWidth: 2,
                  }]
                }} width={100} height={35} /> : <></>}
              </div>
              <div className={styles.ChartInfo}>
                <ul>
                  {
                    User.HappinessOptionsData.map((h, i) => {
                      return <li><span style={{ backgroundColor: h.Colour }}></span><label>{h.Name}</label></li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </> : <></>}

      <div className={`${styles.UpdatesItem} ${styles.Half}`}>
        <h1>
          <span>{Language.Dashboard.EmployeeTurnoverJoined}</span>
          <button onClick={() => setYear(year - 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>
          <label>{year}</label>
          <button onClick={() => setYear(year + 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
        </h1>
        <div className={styles.UpdatesItemInner}>
          <div className={styles.Chart}>
            <div className={styles.ChartInner}>
              {Organisation.Employees.length ? <Bar options={{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }} data={{
                labels: months,
                datasets: [{
                  data: months.map((m, i) => {
                    return Organisation.Employees.filter(e => e.OnboardingComplete && e.Business.StartDate && new Date(e.Business.StartDate).getFullYear() === year && new Date(e.Business.StartDate).getMonth() === i).length
                  }),
                  backgroundColor: [
                    "#8bc34a"
                  ],
                  borderColor: ["white"],
                  borderWidth: 2,
                }]
              }} width={100} height={50} /> : <></>}
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.UpdatesItem} ${styles.Half}`}>
        <h1>
          <span>{Language.Dashboard.EmployeeTurnoverLeft}</span>
          <button onClick={() => setYear(year - 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>
          <label>{year}</label>
          <button onClick={() => setYear(year + 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
        </h1>
        <div className={styles.UpdatesItemInner}>
          <div className={styles.Chart}>
            <div className={styles.ChartInner}>
              {Organisation.Employees.length ? <Bar options={{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }} data={{
                labels: months,
                datasets: [{
                  data: months.map((m, i) => {
                    return Organisation.Employees.filter(e => e.OffboardingComplete && e.Business.EndDate && new Date(e.Business.EndDate).getFullYear() === year && new Date(e.Business.EndDate).getMonth() === i).length
                  }),
                  backgroundColor: [
                    "#f44336"
                  ],
                  borderColor: ["white"],
                  borderWidth: 2,
                }]
              }} width={100} height={50} /> : <></>}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.EmployeeGenders}</span>
        </h1>
        <div className={styles.UpdatesItemInner}>
          <div className={styles.Chart}>
            <div className={styles.ChartInner}>
              {Organisation.Employees.length ? <Doughnut options={{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }} data={{
                labels: ['Female', 'Gender Fluid', 'Non Binary', 'Male'],
                datasets: [{
                  data: [
                    Organisation.Employees.filter(e => !StatusColours.find(s => s.Status === e.Status)?.isDismissed && e.Personal.Gender === "FEMALE").length,
                    Organisation.Employees.filter(e => !StatusColours.find(s => s.Status === e.Status)?.isDismissed && e.Personal.Gender === "GENDER FLUID").length,
                    Organisation.Employees.filter(e => !StatusColours.find(s => s.Status === e.Status)?.isDismissed && e.Personal.Gender === "NON BINARY").length,
                    Organisation.Employees.filter(e => !StatusColours.find(s => s.Status === e.Status)?.isDismissed && e.Personal.Gender === "MALE").length
                  ],
                  backgroundColor: [
                    'rgba(139, 195, 74, 1)',
                    'rgba(139, 195, 74, .75)',
                    'rgba(139, 195, 74, .5)',
                    'rgba(139, 195, 74, .25)'
                  ],
                  borderColor: ["white"],
                  borderWidth: 2,
                }]
              }} width={100} height={35} /> : <></>}
            </div>
            <div className={styles.ChartInfo}>
              <ul>
                <li><span style={{ backgroundColor: 'rgba(139, 195, 74, 1)' }}></span><label>FEMALE</label></li>
                <li><span style={{ backgroundColor: 'rgba(139, 195, 74, .75)' }}></span><label>GENDER FLUID</label></li>
                <li><span style={{ backgroundColor: 'rgba(139, 195, 74, .5)' }}></span><label>NON BINARY</label></li>
                <li><span style={{ backgroundColor: 'rgba(139, 195, 74, .25)' }}></span><label>MALE</label></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.SalaryCosts}</span>
        </h1>
        <div className={styles.UpdatesItemInner}>
          <p><b>Yearly</b> £{(Budget("Yearly"))}</p>
          <p><b>Monthly</b> £{Budget("Monthly")}</p>
          <p><b>Weekly</b> £{Budget("Weekly")}</p>
          <p><b>Daily</b> £{Budget("Daily")}</p>
          <p><b>Hourly</b> £{Budget("Hourly")}</p>
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.LeaveRequiringApproval}</span>
          <button onClick={() => setYear(year - 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>
          <label>{year}</label>
          <button onClick={() => setYear(year + 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
          <Link to={`/Portal/Employees/Leave Requests`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.EventsInner}>
          {Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(e => e.CalendarEvents && e.CalendarEvents.length).filter(e => e.CalendarEvents.find(ce => (new Date(ce.StartDate).getFullYear() === year || new Date(ce.EndDate).getFullYear() === year) && StatusColours.find(s => s.Status === ce.Type)?.canEmployee && ce.isApproved === 0)).length ? Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(e => e.CalendarEvents && e.CalendarEvents.length).filter(e => e.CalendarEvents.find(ce => (new Date(ce.StartDate).getFullYear() === year || new Date(ce.EndDate).getFullYear() === year) && StatusColours.find(s => s.Status === ce.Type)?.canEmployee && ce.isApproved === 0)).map(e => {
            return <div className={styles.Events}>
              <h2>
                <span>{e.Personal.Title}{e.Personal.Title ? ". " : ""}{e.Personal.Forename} {e.Personal.Surname}</span>
                <Link to={`/Portal/Employees/Employee/${EncryptValue(e.ID.toString())}/Leave Requests`}>View</Link>
              </h2>

              {e.CalendarEvents.filter((ce: CalendarEvent) => (new Date(ce.StartDate).getFullYear() === year || new Date(ce.EndDate).getFullYear() === year) && StatusColours.find(s => s.Status === ce.Type)?.canEmployee && ce.isApproved === 0).map((event: CalendarEvent) => {
                return <EventRequest URL={`/Portal/Employees/Employee/${EncryptValue(event.EmployeeID.toString())}/Leave Requests`} event={event} />
              })}
            </div>
          }) : <>
            <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h3>No Leave Requiring Approval</h3>
            </div>
          </>}
        </div>
      </div>

      <div className={`${styles.UpdatesItem}`}>
        <h1>
          <span>{months[new Date().getMonth()]}'s Absences</span>
          <Link to={`/Portal/Company Calendar`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.UpdatesItemInner}>
          {
            Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(emp => (emp.CalendarEvents && emp.CalendarEvents.length)).filter(emp => emp.CalendarEvents.filter((event: CalendarEvent) => StatusColours.find(s => s.Status === event.Type)?.canEmployee && +new Date().getMonth() >= +new Date(event.StartDate).getMonth() && +new Date().getMonth() <= +new Date(event.EndDate).getMonth()).length).length ? Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(emp => (emp.CalendarEvents && emp.CalendarEvents.length)).filter(emp => emp.CalendarEvents.filter((event: CalendarEvent) => StatusColours.find(s => s.Status === event.Type)?.canEmployee && +new Date().getMonth() >= +new Date(event.StartDate).getMonth() && +new Date().getMonth() <= +new Date(event.EndDate).getMonth()).length).map((emp: Employee) => {
              return <div className={styles.Events}>
                <h2>
                  <span>{emp.Personal.Title}{emp.Personal.Title ? ". " : ""}{emp.Personal.Forename} {emp.Personal.Surname}</span>
                  <Link to={`/Portal/Employees/Employee/${EncryptValue(emp.ID.toString())}/Calendar`}>View</Link>
                </h2>

                <PortalEmployeeScatterCalendar employee={emp} setEmployee={() => { }} Month={new Date().getMonth() + 1} />
              </div>
            }) : <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h3>No Absences in {months[new Date().getMonth()]}</h3>
            </div>
          }
        </div>
      </div>

      {User.TimeLoggingOptionsData.length ? <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.EmployeesClockedIn}</span>
        </h1>
        <div className={styles.UpdatesItemInner}>
          {Organisation.Employees.filter(e => e.ID && e.Account.ID && e.OnboardingComplete && !e.OffboardingComplete).map(e => {
            return <Link to={`/Portal/Employees/Employee/${EncryptValue(e.ID.toString())}/Worklog`} className={styles.Employee}>
              <h1>
                {e.Personal.Forename.split("")[0]}
                {e.Personal.Surname.split("")[0]}
              </h1>
              <p>
                <b>{e.Personal.Forename} {e.Personal.Surname}</b>
                <span>{e.WorkLog && e.WorkLog.find(w => w.Date === new Date().toDateString()) ? e.WorkLog.find(w => w.Date === new Date().toDateString())?.Log[e.WorkLog.find(w => w.Date === new Date().toDateString()).Log.length - 1].Status ? `${e.WorkLog.find(w => w.Date === new Date().toDateString())?.Log[e.WorkLog.find(w => w.Date === new Date().toDateString()).Log.length - 1].Status}, ${e.WorkLog.find(w => w.Date === new Date().toDateString())?.Log[e.WorkLog.find(w => w.Date === new Date().toDateString()).Log.length - 1].Place}` : `CLOCKED OUT - ${e.WorkLog.find(w => w.Date === new Date().toDateString())?.Log[e.WorkLog.find(w => w.Date === new Date().toDateString()).Log.length - 1].StartTime}` : <i>NOT CLOCKED IN</i>}</span>
              </p>
            </Link>
          })}
        </div>
      </div> : <></>}

      <div className={`${styles.UpdatesItem}`}>
        <h1>
          <span>{Language.Dashboard.EmployeesRequiringOnboarding}</span>
          <Link to={`/Portal/Tools/Workflows/Onboarding`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.EventsInner}>
          {Organisation.Employees.filter(e => GetProgress(e) < 100).map((employee: Employee) => {
            return <Link className={styles.ListItem} to={`/Portal/Tools/Workflows/Onboarding/Item/${EncryptValue(employee.ID.toString())}`}>
              <h1>
                <span>
                  {employee.Personal.Forename} {employee.Personal.Surname}
                  <small>
                    {employee.Business.CurrentEmployment.JobTitle ? employee.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}
                  </small>
                </span>
                {/* <b>{workflow.Applicants.length} Applicants</b>
                  <small className={workflow.isPublished ? styles.Green : styles.Red}>{workflow.isDeleted ? "Closed" : workflow.isPublished ? "Published" : "Not Published"}</small> */}
                <div className={styles.Storage}>
                  <div style={{ width: `${GetProgress(employee)}%` }} className={`${styles.StorageProgress} ${GetProgress(employee) >= 100 ? styles.Green : ""}`}></div>
                </div>
              </h1>
            </Link>
          })}
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.BankHolidays} ({Organisation.BankHolidays ? Organisation.BankHolidays.find(bank => bank.Year === year)?.Days.length : 0})</span>
          <button onClick={() => setYear(year - 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>
          <label>{year}</label>
          <button onClick={() => setYear(year + 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
          <Link to={`/Portal/Company%20Calendar`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.EventsInner}>
          {
            Organisation.BankHolidays ? Organisation.BankHolidays.filter(bank => bank.Year === new Date().getFullYear()).length ? Organisation.BankHolidays.filter(bank => bank.Year === year).map(bank => {
              return <>
                {
                  bank.Days.map(d => {
                    return <div className={styles.ListItem}>
                      <h1>
                        <span>{d.title}
                          <small>
                            {new Date(d.date).toDateString()}
                          </small>
                        </span>
                      </h1>
                    </div>
                  })
                }
              </>
            }) : <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h3>No Bank Holidays Remaining</h3>
            </div> : <></>
          }
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{months[new Date().getMonth()]}'s Birthdays</span>
        </h1>
        <div className={styles.EventsInner}>
          {
            Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(emp => (emp.Personal.DateOfBirth && new Date(emp.Personal.DateOfBirth).getMonth() === new Date().getMonth())).length ? Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(emp => (emp.Personal.DateOfBirth && new Date(emp.Personal.DateOfBirth).getMonth() === new Date().getMonth())).map((emp: Employee) => {
              return <div className={`${styles.Event} ${styles.BIRTHDAY}`}>
                <h2>
                  <b>
                    <i>BIRTHDAY</i>
                  </b>
                  <span>
                    {emp.Personal.Title}{emp.Personal.Title ? ". " : ""}{emp.Personal.Forename} {emp.Personal.Surname}
                    <small>{new Date(`${new Date().getFullYear()}-${(new Date(emp.Personal.DateOfBirth).getMonth() + 1) < 10 ? "0" : ""}${new Date(emp.Personal.DateOfBirth).getMonth() + 1}-${new Date(emp.Personal.DateOfBirth).getDate() < 10 ? "0" : ""}${new Date(emp.Personal.DateOfBirth).getDate()}`).toLocaleDateString(window.navigator.language)}</small>
                  </span>
                </h2>
              </div>
            }) : <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h3>No Birthdays in {months[new Date().getMonth()]}</h3>
            </div>
          }
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.TodaysAbsences}</span>
        </h1>
        <div className={styles.EventsInner}>
          {
            Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(emp => (emp.CalendarEvents && emp.CalendarEvents.length)).filter(emp => emp.CalendarEvents.filter((event: CalendarEvent) => StatusColours.find(s => s.Status === event.Type)?.canEmployee && +new Date() >= +new Date(event.StartDate) && +new Date() <= +new Date(event.EndDate)).length).length ? Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(emp => (emp.CalendarEvents && emp.CalendarEvents.length)).filter(emp => emp.CalendarEvents.filter((event: CalendarEvent) => StatusColours.find(s => s.Status === event.Type)?.canEmployee && +new Date() >= +new Date(event.StartDate) && +new Date() <= +new Date(event.EndDate)).length).map((emp: Employee) => {
              return <div className={styles.Events}>
                <h2>
                  <span>{emp.Personal.Title}{emp.Personal.Title ? ". " : ""}{emp.Personal.Forename} {emp.Personal.Surname}</span>
                  <Link to={`/Portal/Employees/Employee/${EncryptValue(emp.ID.toString())}/Calendar`}>View</Link>
                </h2>

                {emp.CalendarEvents.filter((event: CalendarEvent) => StatusColours.find(t => t.Status === event.Type) ? !StatusColours.find(t => t.Status === event.Type)?.Hide : true).filter((event: CalendarEvent) => +new Date() >= +new Date(event.StartDate) && +new Date() <= +new Date(event.EndDate)).map((event: CalendarEvent) => {
                  return <div style={{ borderLeftColor: StatusColours.find(t => t.Status === event.Type)?.Colour }} className={`${styles.Event}`}>
                    <h2 style={{ color: StatusColours.find(t => t.Status === event.Type)?.Colour }}>
                      <b style={{ backgroundColor: StatusColours.find(t => t.Status === event.Type)?.Colour }}>
                        <i style={{ color: StatusColours.find(t => t.Status === event.Type)?.Colour }}>{EmploymentStatus[event.Type]}</i>
                      </b>
                      <span>
                        {event.Title ? event.Title : EmploymentStatus[event.Type]}
                        <small>{new Date(event.StartDate).toLocaleDateString(window.navigator.language)} - {new Date(event.EndDate).toLocaleDateString(window.navigator.language)}</small>
                      </span>
                    </h2>
                  </div>
                })}
              </div>
            }) : <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h3>No Absences Today</h3>
            </div>
          }
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>
            {year}'s Absences
          </span>
          <button onClick={() => setYear(year - 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>
          <label>{year}</label>
          <button onClick={() => setYear(year + 1)}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
          <Link to={`/Portal/Company Calendar`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.UpdatesItemInner}>
          {StatusColours.filter(c => c.canEmployee && GetEmployeeDaysOffByStatus(c.Status as EmploymentStatus)).length ? <div className={`${styles.Chart}`}>
            <div className={styles.ChartInner}>
              {Organisation.Employees.length ? <Doughnut options={{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }} data={{
                labels: StatusColours.filter(c => c.canEmployee).map(c => { return EmploymentStatus[c.Status] }),
                datasets: [{
                  data: StatusColours.filter(c => c.canEmployee).map(c => { return GetEmployeeDaysOffByStatus(c.Status as EmploymentStatus) }),
                  backgroundColor: StatusColours.filter(c => c.canEmployee).map(c => { return c.Colour }),
                  borderColor: ["white"],
                  borderWidth: 2,
                }]
              }} width={100} height={35} /> : <></>}
            </div>
            <div className={styles.ChartInfo}>
              <ul>
                {
                  StatusColours.filter(c => c.canEmployee && GetEmployeeDaysOffByStatus(c.Status as EmploymentStatus)).map(c => {
                    return <li><span style={{ backgroundColor: c.Colour }}></span><label>{EmploymentStatus[c.Status]}</label></li>
                  })
                }
              </ul>
            </div>
          </div> : <div className={styles.Message}>
            <i>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </i>
            <h3>No Leave Reported</h3>
          </div>}
        </div>
      </div>

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.Managers}</span>
          <Link to={`/Portal/Employees/Departments`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.UpdatesItemInner}>
          {Organisation.Departments ? Organisation.Employees.filter(e => e.ID && StatusColours.find(c => !c.isDismissed && c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))).filter(e => Organisation.Departments.find(d => d.Manager && d.Manager.ID === e.ID)).map(e => {
            return <Link to={`/Portal/Employees/Employee/${EncryptValue(e.ID.toString())}/Corporate`} className={styles.Employee}>
              <h1>
                {e.Personal.Forename.split("")[0]}
                {e.Personal.Surname.split("")[0]}
              </h1>
              <p>
                <b>{e.Personal.Forename} {e.Personal.Surname}</b>
                <span>{Organisation.Departments.filter(d => d.Manager && d.Manager.ID === e.ID).map((d) => d.Title).join(", ")} Manager</span>
              </p>
            </Link>
          }) : <></>}
        </div>
      </div >

      <div className={styles.UpdatesItem}>
        <h1>
          <span>{Language.Dashboard.TeamLeaders}</span>
          <Link to={`/Portal/Employees/Teams`}>
            <i>
              <FontAwesomeIcon icon={faCogs} />
            </i>
          </Link>
        </h1>
        <div className={styles.UpdatesItemInner}>
          {Organisation.Teams ? Organisation.Employees.filter(e => e.ID && !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).filter(e => Organisation.Teams.find(t => t.Leader && t.Leader.ID === e.ID)).map(e => {
            return <Link to={`/Portal/Employees/Employee/${EncryptValue(e.ID.toString())}/Corporate`} className={styles.Employee}>
              <h1>
                {e.Personal.Forename.split("")[0]}
                {e.Personal.Surname.split("")[0]}
              </h1>
              <p>
                <b>{e.Personal.Forename} {e.Personal.Surname}</b>
                <span>{Organisation.Teams.find(t => t.Leader && t.Leader.ID === e.ID)?.Title} Leader</span>
              </p>
            </Link>
          }) : <></>}
        </div>
      </div >
    </div > : <></>
    }
  </>);
};

export default PortalDashboard;
