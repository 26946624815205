// extracted by mini-css-extract-plugin
export var Button = "Annuel-Leave-Calculator-module--Button--c1de2";
export var Calculator = "Annuel-Leave-Calculator-module--Calculator--99237";
export var CalculatorInner = "Annuel-Leave-Calculator-module--CalculatorInner--fbce9";
export var CalculatorMain = "Annuel-Leave-Calculator-module--CalculatorMain--945c4";
export var CalculatorSidebar = "Annuel-Leave-Calculator-module--CalculatorSidebar--3e6d8";
export var CalculatorSidebarInner = "Annuel-Leave-Calculator-module--CalculatorSidebarInner--fb191";
export var Checkbox = "Annuel-Leave-Calculator-module--Checkbox--e12d2";
export var CheckboxInner = "Annuel-Leave-Calculator-module--CheckboxInner--51e22";
export var CheckboxInnerSlider = "Annuel-Leave-Calculator-module--CheckboxInnerSlider--8637a";
export var Eligible = "Annuel-Leave-Calculator-module--Eligible--f9894";
export var Form = "Annuel-Leave-Calculator-module--Form--1d8b5";
export var Full = "Annuel-Leave-Calculator-module--Full--46b33";
export var Green = "Annuel-Leave-Calculator-module--Green--62e5d";
export var Info = "Annuel-Leave-Calculator-module--Info--2e178";
export var Input = "Annuel-Leave-Calculator-module--Input--608c4";
export var Print = "Annuel-Leave-Calculator-module--Print--61b95";
export var PrintResults = "Annuel-Leave-Calculator-module--PrintResults--b403c";
export var Red = "Annuel-Leave-Calculator-module--Red--e90bf";
export var Results = "Annuel-Leave-Calculator-module--Results--8211e";
export var Total = "Annuel-Leave-Calculator-module--Total--e35f6";