// extracted by mini-css-extract-plugin
export var Buttons = "Progress-module--Buttons--60553";
export var Filter = "Progress-module--Filter--0ebde";
export var Green = "Progress-module--Green--1099f";
export var Orange = "Progress-module--Orange--62305";
export var Progress = "Progress-module--Progress--33bc1";
export var Red = "Progress-module--Red--64446";
export var Section = "Progress-module--Section--bb8d9";
export var SectionHeader = "Progress-module--SectionHeader--966a0";
export var SectionProgress = "Progress-module--SectionProgress--431e1";
export var Sections = "Progress-module--Sections--da570";
export var Storage = "Progress-module--Storage--9fdc0";
export var StorageProgress = "Progress-module--StorageProgress--81f61";