// extracted by mini-css-extract-plugin
export var Address = "Settings-module--Address--67d09";
export var Basic = "Settings-module--Basic--6e0a7";
export var Body = "Settings-module--Body--f3c84";
export var Buttons = "Settings-module--Buttons--71561";
export var Close = "Settings-module--Close--bdd69";
export var Closed = "Settings-module--Closed--5616d";
export var Colour = "Settings-module--Colour--e8748";
export var Deleted = "Settings-module--Deleted--7d473";
export var Error = "Settings-module--Error--1a5e2";
export var Form = "Settings-module--Form--d7559";
export var FormHeader = "Settings-module--FormHeader--bc4c2";
export var FormInner = "Settings-module--FormInner--5c4ff";
export var Gray = "Settings-module--Gray--68f87";
export var Input = "Settings-module--Input--aca51";
export var InputInner = "Settings-module--InputInner--d55db";
export var LogoUpload = "Settings-module--LogoUpload--b026c";
export var LogoUploadPreview = "Settings-module--LogoUploadPreview--e3ff4";
export var Main = "Settings-module--Main--f51e6";
export var Message = "Settings-module--Message--908eb";
export var OpeningTimes = "Settings-module--OpeningTimes--a750b";
export var OpeningTimesBlock = "Settings-module--OpeningTimesBlock--ce68b";
export var OpeningTimesRow = "Settings-module--OpeningTimesRow--0cb94";
export var OpeningTimesRowCheckbox = "Settings-module--OpeningTimesRowCheckbox--5832d";
export var OpeningTimesRowInner = "Settings-module--OpeningTimesRowInner--6a36b";
export var OpeningTimesRowInput = "Settings-module--OpeningTimesRowInput--9fdd5";
export var OpeningTimesRowPicker = "Settings-module--OpeningTimesRowPicker--6d056";
export var Search = "Settings-module--Search--ab9bd";
export var Success = "Settings-module--Success--dee55";
export var half = "Settings-module--half--8d70b";
export var quarter = "Settings-module--quarter--3969d";
export var third = "Settings-module--third--e84eb";