import { faCheckDouble, faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { ProgressSection } from '../../../../Classes/ProgressSection';
import Input from '../../../../components/Input/input';
import { OrganisationContext, PackageContext, UserContext } from '../../../../Context/UserContext';
import { AccountProgressCheck } from '../../../../Services/ProfileProgress';
import { PortalAccountMain } from '../Account';
import * as styles from './Progress.module.scss';

const PortalAccountProgress: React.FC = () => {
  const { User } = useContext(UserContext);
  const { Packages } = useContext(PackageContext);
  const { Organisation } = useContext(OrganisationContext);
  const [sections, setSections] = useState<ProgressSection[]>([] as ProgressSection[]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showCompleted, setShowCompleted] = useState<boolean>(true);

  useEffect(() => {
    if (User.ID && Organisation.Employees && Packages.length)
      setSections(AccountProgressCheck(User, Organisation, Packages));
  }, [User, Organisation, Packages])

  const GetProgress = () => {
    let total: number = sections.length;
    let current: number = sections.filter(s => s.isDone).length;
    return (100 / total) * current;
  }

  return (<PortalAccountMain>
    <div className={styles.Sections}>
      <div className={styles.SectionProgress}>
        <h1>Set up your PrimeHR Account</h1>
        <b>
          {sections.filter(s => !s.isDone).length} out of {sections.length} Tasks Remaining

          {sections.filter(s => s.isDone).length !== sections.length ? <span>
            Next Task: <i onClick={() => navigate(sections.filter(s => !s.isDone)[0].Route)}>{sections.filter(s => !s.isDone)[0].Title}</i>
          </span> : <span>
            <i className={styles.Green}>Completed!</i>
          </span>}
        </b>

        <p>{GetProgress().toFixed(0)}% Complete</p>

        <div className={styles.Storage}>
          <div style={{ width: `${GetProgress()}%` }} className={`${styles.StorageProgress} ${GetProgress() >= 100 ? styles.Green : ""}`}></div>
        </div>
      </div>

      <div className={styles.Filter}>
        <Input Type="checkbox" OnChange={(c) => setShowCompleted(c)} Placeholder={''} Label={"Show Completed Tasks"} Value={showCompleted} />
      </div>

      {
        sections.filter((section: ProgressSection) => (showCompleted || (!showCompleted && !section.isDone))).filter((s, index: number) => showAll ? true : index < 5).map((section: ProgressSection, index: number) => {
          return <Link to={section.Route} className={styles.Section}>
            <div className={styles.SectionHeader}>
              <i className={section.isDone ? styles.Green : ""}>
                {section.isDone ? <FontAwesomeIcon icon={faCheckDouble} /> : (index + 1)}
              </i>
              <h2>
                {section.Title}
                <small>{section.Description}</small>
              </h2>
              <b>
                <FontAwesomeIcon icon={faChevronRight} />
              </b>
            </div>
          </Link>
        })
      }

      {sections.filter(s => (showCompleted || (!showCompleted && !s.isDone))).length > 5 ? <div className={styles.Buttons}>
        <button type="button" onClick={() => setShowAll(!showAll)}>
          <i>
            <FontAwesomeIcon icon={showAll ? faChevronUp : faChevronDown} />
          </i>
          <span>{showAll ? "Show Less" : "Show More"}</span>
        </button>
      </div> : <></>}
    </div>
  </PortalAccountMain>);
};

export default PortalAccountProgress;
