import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Settings.module.scss';
import * as general from '../Account.module.scss';
import { UserContext } from '../../../../Context/UserContext';
import { Fetch } from '../../../../Services/Fetch';
import { API_SaveUser } from '../../../../Services/ApiRoutes';
import { HappinessOption, TaskStatus, TimeLoggingOption, User } from '../../../../Classes/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faBusinessTime, faGraduationCap, faHamburger, faHandshake, faPauseCircle, faPlusCircle, faRefresh, faSave, faSearch, faSpinner, faStopCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import Input from '../../../../components/Input/input';
import { PortalAccountMain } from '../Account';
import { Button } from '../../Courses/Components/Button/Button';
import Picker from '../../../../components/IconPicker/picker';
import { WorkStatus } from '../../../../Enums/EmploymentStatus';
import { faFaceFrown, faFaceGrinBeam, faFaceMeh, faFaceSadTear, faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import languageData from '../../../../Services/Language.json';
import { getGlossary } from '../../../../Services/Glossary';

type ErrorMessage = {
  Message: string;
  Class: string;
}

const PortalAccountSettings: React.FC = () => {
  const { User, setUser, setLanguage, Language } = useContext(UserContext);

  const [filter, setFilter] = useState<string>(Object.keys(languageData)[0]);
  const [search, setSearch] = useState<string>("");
  const [saving, setSaving] = useState<number>(0);
  const [deleteOptions, setDeleteOptions] = useState<string[]>([] as string[]);
  const [deleteTaskStatusOptions, setDeleteTaskStatusOptions] = useState<string[]>([] as string[]);
  const [deleteHappinessOptions, setDeleteHappinessOptions] = useState<string[]>([] as string[]);
  const [timeLoggingOptions, setTimeLoggingOptions] = useState<TimeLoggingOption[]>([] as TimeLoggingOption[]);
  const [happinessOptions, setHappinessOptions] = useState<HappinessOption[]>([] as HappinessOption[]);
  const [taskStatusOptions, setTaskStatusOptions] = useState<TaskStatus[]>([] as TaskStatus[]);
  const [language, setLanguageData] = useState<any>(languageData);
  const [newOption, setNewOption] = useState<TimeLoggingOption>({
    Name: "",
    Colour: "#000",
    Icon: faStopCircle
  } as TimeLoggingOption as TimeLoggingOption);
  const [happinessOption, setNewHappinessOption] = useState<HappinessOption>({
    Name: "",
    Colour: "#000",
    Icon: faStopCircle
  } as HappinessOption);
  const [taskStatusOption, setTaskStatusOption] = useState<TaskStatus>({
    Name: "",
    Colour: "#000",
    Position: 0
  } as TaskStatus);

  const status: WorkStatus[] = [WorkStatus.ON_BREAK, WorkStatus.ON_LUNCH, WorkStatus.OVERTIME, WorkStatus.TRAINING, WorkStatus.IN_MEETING] as WorkStatus[];
  const icons: IconDefinition[] = [faPauseCircle, faHamburger, faBusinessTime, faGraduationCap, faHandshake];
  const colours: string[] = ["#ff9800", "#ff5722", "#00bcd4", "#4caf50", "#ffeb3b"];

  const happinessList: string[] = ["Upset", "Unhappy", "Average", "Happy", "Excited"];
  const happinessIcons: IconDefinition[] = [faFaceSadTear, faFaceFrown, faFaceMeh, faFaceSmile, faFaceGrinBeam];
  const happinessColours: string[] = ["#f44336", "#ff9800", "#ffc107", "#8bc34a", "#4caf50"];

  const taskList: string[] = ["Not Started", "In Progress", "In Review", "Sign Off Required", "Completed"];
  const taskColours: string[] = ["#f44336", "#ff9800", "#ffc107", "#8bc34a", "#4caf50"];

  useEffect(() => {
    if (User.ID) {
      setTimeLoggingOptions(User.TimeLoggingOptionsData ?? []);
      setHappinessOptions(User.HappinessOptionsData ?? []);
      setTaskStatusOptions(User.TaskStatusOptionsData ?? []);
      setLanguageData(getGlossary(User.GlossaryData));
    }
  }, [User])

  const SaveProfile = async (e: any, section: number) => {
    e.preventDefault();
    let message: ErrorMessage = { Class: "Error" } as ErrorMessage;
    setSaving(section);

    if (User.Company && User.Employees) {
      User.isConsultant = User.Employees === "1";
      if (section === 1)
        User.TimeLoggingOptions = JSON.stringify(timeLoggingOptions.filter((option: TimeLoggingOption) => !deleteOptions.includes(option.Name)));
      else if (section === 2)
        User.HappinessOptions = JSON.stringify(happinessOptions.filter((option: TimeLoggingOption) => !deleteHappinessOptions.includes(option.Name)));
      else if (section === 3) {
        const languageList: any = {};
        const languageListData: any = JSON.parse(JSON.stringify(languageData));

        Object.keys(languageListData).map((title) => {
          Object.keys(languageListData[title]).map((value) => {
            if (languageListData[title][value] !== language[title][value]) {
              if (!languageList[title])
                languageList[title] = {};

              languageList[title][value] = language[title][value];
            }
          })
        })

        User.Glossary = JSON.stringify(languageList);
      }
      else if (section === 4)
        User.TaskStatusOptions = JSON.stringify(taskStatusOptions.filter((option: TaskStatus) => !deleteHappinessOptions.includes(option.Name)));

      await Fetch(`${API_SaveUser}`, User).then((res: User) => {
        if (res.ID) {
          setUser({
            ...res,
            TimeLoggingOptionsData: JSON.parse(res.TimeLoggingOptions),
            OpeningTimesData: JSON.parse(res.OpeningTimes),
            HappinessOptionsData: JSON.parse(res.HappinessOptions),
            GlossaryData: JSON.parse(res.Glossary),
            TaskStatusOptionsData: JSON.parse(res.TaskStatusOptions)
          });
          setDeleteOptions([]);
          setDeleteHappinessOptions([]);
          setLanguage(getGlossary(JSON.parse(res.Glossary)));
        }
      });
    } else {
      message.Message = "Please Ensure All Fields Are Filled In";
    }

    setSaving(0);
  }

  const AddTimeLoggingOption = (Index: number, Option: TimeLoggingOption) => {
    let Options: TimeLoggingOption[] = JSON.parse(JSON.stringify(timeLoggingOptions));
    Options[Index] = Option
    setTimeLoggingOptions(Options);
  }

  const AddHappinessOption = (Index: number, Option: HappinessOption) => {
    let Options: HappinessOption[] = JSON.parse(JSON.stringify(happinessOptions));
    Options[Index] = Option
    setHappinessOptions(Options);
  }

  const AddTaskStatusOption = (Index: number, Option: TaskStatus) => {
    let Options: TaskStatus[] = JSON.parse(JSON.stringify(taskStatusOptions));
    Options[Index] = Option
    setTaskStatusOptions(Options);
  }

  const ChangeLanguage = (Title: string, Value: string, NewValue: string) => {
    let languageList: any = JSON.parse(JSON.stringify(language));
    languageList[Title][Value] = NewValue;
    setLanguageData(languageList);
  }

  const AddNewOption = () => {
    let Options: TimeLoggingOption[] = JSON.parse(JSON.stringify(timeLoggingOptions));
    Options.push(newOption);
    setTimeLoggingOptions(Options);
    setNewOption({
      Name: "",
      Colour: "",
      Icon: faStopCircle
    } as TimeLoggingOption);
  }

  const AddNewHappinessOption = () => {
    let Options: HappinessOption[] = JSON.parse(JSON.stringify(happinessOptions));
    Options.push(happinessOption);
    setHappinessOptions(Options);
    setNewHappinessOption({
      Name: "",
      Colour: "",
      Icon: faStopCircle
    } as TimeLoggingOption);
  }

  const AddNewTaskStatusOption = () => {
    let Options: TaskStatus[] = JSON.parse(JSON.stringify(taskStatusOptions));
    Options.push(taskStatusOption);
    setTaskStatusOptions(Options);
    setTaskStatusOption({
      Name: "",
      Colour: "",
      Position: 0
    } as TaskStatus);
  }

  const DeleteOption = (option: TimeLoggingOption | HappinessOption | TaskStatus, deleteOptionsList: string[], setOptions: (options: string[]) => void) => {
    const options: string[] = JSON.parse(JSON.stringify(deleteOptionsList));
    const optionExists: number = options.findIndex((opt: string) => opt === option.Name);

    if (optionExists >= 0) {
      options.splice(optionExists, 1);
    } else {
      options.push(option.Name);
    }

    setOptions(options);
  }

  const isDeleted = (option: TimeLoggingOption | HappinessOption | TaskStatus, deleteOptionsList: string[]) => {
    const options: string[] = JSON.parse(JSON.stringify(deleteOptionsList));
    const optionExists: string | undefined = options.find((opt: string) => opt === option.Name);

    if (optionExists)
      return true;
    return false;
  }

  const RestoreDefaults = () => {
    setTimeLoggingOptions(status.map((status: WorkStatus, index: number) => {
      return {
        Name: status,
        Colour: colours[index],
        Icon: icons[index]
      } as TimeLoggingOption
    }));
  }

  const RestoreHappinessDefaults = () => {
    setHappinessOptions(happinessList.map((status: string, index: number) => {
      return {
        Name: status,
        Colour: happinessColours[index],
        Icon: happinessIcons[index]
      } as TimeLoggingOption
    }));
  }

  const RestoreTaskStatusDefaults = () => {
    setTaskStatusOptions(taskList.map((status: string, index: number) => {
      return {
        Name: status,
        Colour: taskColours[index],
        Position: index
      } as TaskStatus
    }));
  }

  const RestoreLanguageDefaults = () => {
    setLanguageData(languageData);
  }

  return (<>
    <PortalAccountMain>
      <form className={general.Form} onSubmit={(e) => SaveProfile(e, 1)}>
        <h2>Time Logging Options</h2>
        <p>Your business's time logging options that your employees can select. Alter them to suit your company needs.
          <span>
            <b className={general.Red}>Warning:</b> Removing options after use may result in data not being tracked within the Worklog sections of the Portal.
          </span>
          <span>
            <b>Hint:</b> Removing all options will cause the Time Logging tool to hide for your Employees
          </span>
        </p>

        <div className={general.FormInner}>
          <div className={styles.OpeningTimes}>
            {
              timeLoggingOptions.map((option: TimeLoggingOption, ind: number) => {
                return <div className={`${styles.OpeningTimesRow} ${isDeleted(option, deleteOptions) ? styles.Deleted : ""}`}>
                  <div className={`${styles.OpeningTimesRowInner}`}>
                    <div className={styles.OpeningTimesRowInput}>
                      <Input Type="text" Value={`${option.Name}`} OnChange={(v) => AddTimeLoggingOption(ind, { ...option, Name: v })} Placeholder="..." Label="" />
                    </div>
                    <b>Colour</b>
                    <div className={`${styles.OpeningTimesRowInput} ${styles.Colour}`}>
                      <Input Type="color" Value={`${option.Colour}`} OnChange={(v) => AddTimeLoggingOption(ind, { ...option, Colour: v })} Placeholder="..." Label="" />
                    </div>
                    <div className={styles.OpeningTimesRowPicker}>
                      <Picker Placeholder='Icon' Label="" Select={(i) => AddTimeLoggingOption(ind, { ...option, Icon: i })} Icon={option.Icon as IconDefinition} />
                    </div>
                  </div>

                  <Button OnClick={() => DeleteOption(option, deleteOptions, setDeleteOptions)} Color={isDeleted(option, deleteOptions) ? "GreenLink" : "RedLink"}>
                    <i>
                      <FontAwesomeIcon icon={isDeleted(option, deleteOptions) ? faRefresh : faTrash} />
                    </i>
                    {isDeleted(option, deleteOptions) ? "Redeem" : "Remove"}
                  </Button>
                </div>
              })
            }

            <h3>Add Option</h3>
            <div className={styles.OpeningTimesBlock}>
              <div className={styles.OpeningTimesRow}>
                <div className={`${styles.OpeningTimesRowInner}`}>
                  <div className={styles.OpeningTimesRowInput}>
                    <Input Type="text" Value={`${newOption.Name}`} OnChange={(v) => setNewOption({ ...newOption, Name: v })} Placeholder="..." Label="" />
                  </div>
                  <b>Colour</b>
                  <div className={`${styles.OpeningTimesRowInput} ${styles.Colour}`}>
                    <Input Type="color" Value={`${newOption.Colour}`} OnChange={(v) => setNewOption({ ...newOption, Colour: v })} Placeholder="..." Label="" />
                  </div>
                  <div className={styles.OpeningTimesRowPicker}>
                    <Picker Placeholder='Icon' Label="" Select={(i) => setNewOption({ ...newOption, Icon: i })} Icon={newOption.Icon as IconDefinition} />
                  </div>
                </div>
                <Button OnClick={() => AddNewOption()} Color="Link" Disabled={!newOption.Name}>
                  <i>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </i>
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.Buttons}>
            <Button Color="Link" OnClick={() => RestoreDefaults()}>
              <i>
                <FontAwesomeIcon icon={faRefresh} />
              </i>
              Restore Defaults
            </Button>

            <Button Disabled={saving > 0} Type='submit'>
              <i>
                <FontAwesomeIcon icon={saving === 1 ? faSpinner : faSave} spin={saving === 1} />
              </i>
              {saving === 1 ? "Saving..." : "Save Options"}
            </Button>
          </div>
        </div>
      </form>

      <form className={general.Form} onSubmit={(e) => SaveProfile(e, 2)}>
        <h2>{Language.Applications.MoodMonitoring} Options</h2>
        <p>Your business's {Language.Applications.MoodMonitoring} options that your employees can select. Alter them to suit your company needs.
          <span>
            <b className={general.Red}>Warning:</b> Removing options after use may result in data not being tracked within the {Language.Applications.MoodMonitoring} sections of the Portal.
          </span>
          <span>
            <b>Hint:</b> Removing all options will cause the {Language.Applications.MoodMonitoring} tool to hide for your Employees
          </span>
        </p>

        <div className={general.FormInner}>
          <div className={styles.OpeningTimes}>
            {
              happinessOptions.map((option: HappinessOption, ind: number) => {
                return <div className={`${styles.OpeningTimesRow} ${isDeleted(option, deleteHappinessOptions) ? styles.Deleted : ""}`}>
                  <div className={`${styles.OpeningTimesRowInner}`}>
                    <div className={styles.OpeningTimesRowInput}>
                      <Input Type="text" Value={`${option.Name}`} OnChange={(v) => AddHappinessOption(ind, { ...option, Name: v })} Placeholder="..." Label="" />
                    </div>
                    <b>Colour</b>
                    <div className={`${styles.OpeningTimesRowInput} ${styles.Colour}`}>
                      <Input Type="color" Value={`${option.Colour}`} OnChange={(v) => AddHappinessOption(ind, { ...option, Colour: v })} Placeholder="..." Label="" />
                    </div>
                    <div className={styles.OpeningTimesRowPicker}>
                      <Picker Placeholder='Icon' Label="" Select={(i) => AddHappinessOption(ind, { ...option, Icon: i })} Icon={option.Icon as IconDefinition} />
                    </div>
                  </div>

                  <Button OnClick={() => DeleteOption(option, deleteHappinessOptions, setDeleteHappinessOptions)} Color={isDeleted(option, deleteHappinessOptions) ? "GreenLink" : "RedLink"}>
                    <i>
                      <FontAwesomeIcon icon={isDeleted(option, deleteHappinessOptions) ? faRefresh : faTrash} />
                    </i>
                    {isDeleted(option, deleteHappinessOptions) ? "Redeem" : "Remove"}
                  </Button>
                </div>
              })
            }

            <h3>Add Option</h3>
            <div className={styles.OpeningTimesBlock}>
              <div className={styles.OpeningTimesRow}>
                <div className={`${styles.OpeningTimesRowInner}`}>
                  <div className={styles.OpeningTimesRowInput}>
                    <Input Type="text" Value={`${happinessOption.Name}`} OnChange={(v) => setNewHappinessOption({ ...happinessOption, Name: v })} Placeholder="..." Label="" />
                  </div>
                  <b>Colour</b>
                  <div className={`${styles.OpeningTimesRowInput} ${styles.Colour}`}>
                    <Input Type="color" Value={`${happinessOption.Colour}`} OnChange={(v) => setNewHappinessOption({ ...happinessOption, Colour: v })} Placeholder="..." Label="" />
                  </div>
                  <div className={styles.OpeningTimesRowPicker}>
                    <Picker Placeholder='Icon' Label="" Select={(i) => setNewHappinessOption({ ...happinessOption, Icon: i })} Icon={happinessOption.Icon as IconDefinition} />
                  </div>
                </div>
                <Button OnClick={() => AddNewHappinessOption()} Color="Link" Disabled={!happinessOption.Name}>
                  <i>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </i>
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.Buttons}>
            <Button Color="Link" OnClick={() => RestoreHappinessDefaults()}>
              <i>
                <FontAwesomeIcon icon={faRefresh} />
              </i>
              Restore Defaults
            </Button>

            <Button Disabled={saving > 0} Type='submit'>
              <i>
                <FontAwesomeIcon icon={saving === 2 ? faSpinner : faSave} spin={saving === 2} />
              </i>
              {saving === 2 ? "Saving..." : "Save Options"}
            </Button>
          </div>
        </div>
      </form>

      <form className={general.Form} onSubmit={(e) => SaveProfile(e, 4)}>
        <h2>Task Status Options</h2>
        <p>
          Your business's {Language.Applications.TaskManager} status options that you can select on your task tickets. Alter them to suit your company needs.
          <span>
            <b className={general.Red}>Warning:</b> Removing options after use may result in data not being tracked within the {Language.Applications.TaskManager} sections of the Portal.
          </span>
        </p>

        <div className={general.FormInner}>
          <div className={styles.OpeningTimes}>
            {
              taskStatusOptions.map((option: TaskStatus, ind: number) => {
                return <div className={`${styles.OpeningTimesRow} ${isDeleted(option, deleteTaskStatusOptions) ? styles.Deleted : ""}`}>
                  <div className={`${styles.OpeningTimesRowInner}`}>
                    <div className={styles.OpeningTimesRowInput}>
                      <Input Type="text" Value={`${option.Name}`} OnChange={(v) => AddTaskStatusOption(ind, { ...option, Name: v })} Placeholder="..." Label="" />
                    </div>
                    <b>Colour</b>
                    <div className={`${styles.OpeningTimesRowInput} ${styles.Colour}`}>
                      <Input Type="color" Value={`${option.Colour}`} OnChange={(v) => AddTaskStatusOption(ind, { ...option, Colour: v })} Placeholder="..." Label="" />
                    </div>
                  </div>

                  <Button OnClick={() => DeleteOption(option, deleteTaskStatusOptions, setDeleteTaskStatusOptions)} Color={isDeleted(option, deleteTaskStatusOptions) ? "GreenLink" : "RedLink"}>
                    <i>
                      <FontAwesomeIcon icon={isDeleted(option, deleteTaskStatusOptions) ? faRefresh : faTrash} />
                    </i>
                    {isDeleted(option, deleteTaskStatusOptions) ? "Redeem" : "Remove"}
                  </Button>
                </div>
              })
            }

            <h3>Add Status</h3>
            <div className={styles.OpeningTimesBlock}>
              <div className={styles.OpeningTimesRow}>
                <div className={`${styles.OpeningTimesRowInner}`}>
                  <div className={styles.OpeningTimesRowInput}>
                    <Input Type="text" Value={`${taskStatusOption.Name}`} OnChange={(v) => setTaskStatusOption({ ...taskStatusOption, Name: v })} Placeholder="..." Label="" />
                  </div>
                  <b>Colour</b>
                  <div className={`${styles.OpeningTimesRowInput} ${styles.Colour}`}>
                    <Input Type="color" Value={`${taskStatusOption.Colour}`} OnChange={(v) => setTaskStatusOption({ ...taskStatusOption, Colour: v })} Placeholder="..." Label="" />
                  </div>
                </div>
                <Button OnClick={() => AddNewTaskStatusOption()} Color="Link" Disabled={!taskStatusOption.Name}>
                  <i>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </i>
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.Buttons}>
            <Button Color="Link" OnClick={() => RestoreTaskStatusDefaults()}>
              <i>
                <FontAwesomeIcon icon={faRefresh} />
              </i>
              Restore Defaults
            </Button>

            <Button Disabled={saving > 0} Type='submit'>
              <i>
                <FontAwesomeIcon icon={saving === 4 ? faSpinner : faSave} spin={saving === 4} />
              </i>
              {saving === 4 ? "Saving..." : "Save Statuses"}
            </Button>
          </div>
        </div>
      </form>

      <form className={general.Form} onSubmit={(e) => SaveProfile(e, 3)}>
        <h2>Portal Glossary</h2>
        <p>
          The language you want to use across your PrimeHR portal. We understand every business has their own way of speaking.

          <ul>
            {
              Object.keys(languageData).map((title) => {
                return <li className={`${title === filter ? general.Active : ""}`}>
                  <button type="button" title={title} onClick={() => setFilter(title)}>
                    {title.replace("_", " ")}
                  </button>
                </li>
              })
            }
          </ul>
        </p>

        <div className={general.FormInner}>
          <div className={styles.Search}>
            <Input Type="text" OnChange={(v) => setSearch(v)} Icon={faSearch} Placeholder={'...'} Label={'Search...'} Value={search} />
          </div>

          <div className={styles.OpeningTimes}>
            {
              Object.keys(languageData).map((title) => {
                if (title === filter) {
                  return <>
                    {
                      Object.keys(languageData[title]).filter((value) => languageData[title][value].toLowerCase().includes(search.toLowerCase())).map((value) => {
                        return <div className={`${styles.OpeningTimesRow}`}>
                          <div className={`${styles.OpeningTimesRowInner}`}>
                            <span>{languageData[title][value]}:</span>
                            <div className={styles.OpeningTimesRowInput}>
                              <Input Type="text" Value={`${language[title][value]}`} OnChange={(v) => ChangeLanguage(title, value, v)} Placeholder="..." Label="" />
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </>
                }
              })
            }
          </div>

          <div className={styles.Buttons}>
            <Button Color="Link" OnClick={() => RestoreLanguageDefaults()}>
              <i>
                <FontAwesomeIcon icon={faRefresh} />
              </i>
              Restore Defaults
            </Button>

            <Button Disabled={saving > 0} Type='submit'>
              <i>
                <FontAwesomeIcon icon={saving === 3 ? faSpinner : faSave} spin={saving === 3} />
              </i>
              {saving === 3 ? "Saving..." : "Save Glossary"}
            </Button>
          </div>
        </div>
      </form>
    </PortalAccountMain>
  </>);
};

export default PortalAccountSettings;
