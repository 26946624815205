// extracted by mini-css-extract-plugin
export var AccountProgress = "Dashboard-module--AccountProgress--ad224";
export var AccountProgressInner = "Dashboard-module--AccountProgressInner--bd74a";
export var Active = "Dashboard-module--Active--fe4c3";
export var App = "Dashboard-module--App--f4b54";
export var Apps = "Dashboard-module--Apps--8cdff";
export var AppsBlock = "Dashboard-module--AppsBlock--7da6b";
export var BANKHOLIDAY = "Dashboard-module--BANKHOLIDAY--fdf62";
export var BIRTHDAY = "Dashboard-module--BIRTHDAY--36db7";
export var BankHolidays = "Dashboard-module--BankHolidays--e82a0";
export var Bar = "Dashboard-module--Bar--45582";
export var Chart = "Dashboard-module--Chart--f96c5";
export var ChartInfo = "Dashboard-module--ChartInfo--f1882";
export var ChartInner = "Dashboard-module--ChartInner--997fc";
export var Employee = "Dashboard-module--Employee--f9728";
export var Employees = "Dashboard-module--Employees--f62f5";
export var EmployeesInner = "Dashboard-module--EmployeesInner--e6277";
export var Event = "Dashboard-module--Event--b5fa3";
export var Events = "Dashboard-module--Events--186a0";
export var EventsInner = "Dashboard-module--EventsInner--d3098";
export var Filter = "Dashboard-module--Filter--3154f";
export var Full = "Dashboard-module--Full--834ba";
export var Green = "Dashboard-module--Green--3872e";
export var Half = "Dashboard-module--Half--57604";
export var ListItem = "Dashboard-module--ListItem--4b8fc";
export var Loading = "Dashboard-module--Loading--1d9aa";
export var Message = "Dashboard-module--Message--30942";
export var Orange = "Dashboard-module--Orange--ba5a8";
export var Progress = "Dashboard-module--Progress--90c01";
export var Red = "Dashboard-module--Red--3293c";
export var Show = "Dashboard-module--Show--18723";
export var Storage = "Dashboard-module--Storage--2d2c0";
export var StorageProgress = "Dashboard-module--StorageProgress--217f4";
export var Tabs = "Dashboard-module--Tabs--888ea";
export var Third = "Dashboard-module--Third--7f431";
export var TwoThirds = "Dashboard-module--TwoThirds--436ca";
export var Unapproved = "Dashboard-module--Unapproved--74b27";
export var Updates = "Dashboard-module--Updates--78ab2";
export var UpdatesBlock = "Dashboard-module--UpdatesBlock--99d81";
export var UpdatesItem = "Dashboard-module--UpdatesItem--5a9e2";
export var UpdatesItemInner = "Dashboard-module--UpdatesItemInner--9f36a";