import { faCalendarWeek, faChevronLeft, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Employee, Shift } from '../../../Classes/Employee';
import { Times } from '../../../Classes/User';
import Input from '../../../components/Input/input';
import { Modal } from '../../../components/Modal/Modal';
import { OrganisationContext, UserContext } from '../../../Context/UserContext';
import { API_SaveShift } from '../../../Services/ApiRoutes';
import { DecryptValue, EncryptValue } from '../../../Services/Crypto';
import { Fetch } from '../../../Services/Fetch';
import { Button } from '../Courses/Components/Button/Button';
import * as styles from './ShiftScheduling.module.scss';
import * as general from '../Employees/Styles.module.scss';
import UnassignedTitle from '../../../components/UnassignedTitle/UnassignedTitle';

const PortalRota: React.FC = (props: any) => {
  const CustomerID: string = props.CustomerID ? props.CustomerID : "";

  const defaultshift: Shift = {
    ID: 0,
    EmployeeID: 0,
    ClientID: 0,
    CustomerID: 0,
    Date: "",
    StartTime: "",
    EndTime: ""
  } as Shift;
  const [dateTitle, setDateTitle] = useState<string>(new Date().toDateString());
  const [dates, setDates] = useState<Date[]>([new Date()] as Date[]);
  const [tab, setTab] = useState<number>(0);
  const [minTime, setMinTime] = useState<string>("00:00");
  const [maxTime, setMaxTime] = useState<string>("23:59");
  const [show, setShow] = useState<boolean>(false);
  const [shift, setShift] = useState<Shift>(defaultshift);
  const { Organisation, setOrganisation } = useContext(OrganisationContext);
  const { User, Language } = useContext(UserContext);
  const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    switch (tab) {
      case 0:
        setDateTitle(dates[0].toDateString());
        setDates([dates[0]])
        break;
      case 1:
        GetWeek(dates[0]);
        break;
      case 2:
        GetMonth(dates[0].getMonth(), dates[0].getFullYear());
        break;
    }
  }, [tab])

  useEffect(() => {
    if (tab === 0) {
      let times: Times[] = [] as Times[];
      let today: Times | undefined = undefined;

      switch (dates[0].getDay()) {
        case 0:
          times = User.OpeningTimesData.Sun;
          break;
        case 1:
          times = User.OpeningTimesData.Mon;
          break;
        case 2:
          times = User.OpeningTimesData.Tue;
          break;
        case 3:
          times = User.OpeningTimesData.Wed;
          break;
        case 4:
          times = User.OpeningTimesData.Thu;
          break;
        case 5:
          times = User.OpeningTimesData.Fri;
          break;
        case 6:
          times = User.OpeningTimesData.Sat;
          break;
      }

      if (times && times.length) {
        today = times.find(t => t.Date === `${dates[0].getFullYear()}-${(dates[0].getMonth() + 1) < 10 ? "0" : ""}${dates[0].getMonth() + 1}-${dates[0].getDate() < 10 ? "0" : ""}${dates[0].getDate()}`);
        if (!today)
          today = times.find(t => !t.Date);

        if (today && today.Start && today.End) {
          setMinTime(today.Start);
          setMaxTime(today.End);
        } else {
          setMinTime("00:00");
          setMaxTime("23:59");
        }
      } else {
        setMinTime("00:00");
        setMaxTime("23:59");
      }
    }
  }, [dates])

  const SaveShift = async (Shift: Shift) => {
    let shifts: Shift[] = JSON.parse(JSON.stringify(Organisation.Shifts));

    await Fetch(`${API_SaveShift}`, { ...Shift, CustomerID: CustomerID ? parseInt(DecryptValue(CustomerID)) : 0, ClientID: User.ID } as Shift).then((response) => {
      if (Shift.ID === 0)
        shifts.push(response);
      else {
        let index: number = shifts.findIndex((s) => s.ID === Shift.ID);
        shifts[index] = response;
      }
    })

    setOrganisation({ ...Organisation, Shifts: shifts });
    setShow(false);
    setShift(defaultshift);
  }

  const GetMonth = (Month: number, Year: number) => {
    var dates: Date[] = [] as Date[];

    let firstDay: Date = new Date(Year, Month, 1);
    let lastDay: Date = new Date(Month === 11 ? (Year + 1) : Year, Month === 11 ? 0 : (Month + 1), 0);

    for (var d = firstDay; d <= lastDay; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }

    setDates(dates);
    setDateTitle(`${months[dates[0].getMonth()]}, ${dates[0].getFullYear()}`);
  }

  const GetWeek = (ThisDate: Date) => {
    let week: Date[] = [] as Date[];
    ThisDate.setDate((ThisDate.getDate() - ThisDate.getDay() + 1));
    for (var i = 0; i < 7; i++) {
      week.push(
        new Date(ThisDate)
      );
      ThisDate.setDate(ThisDate.getDate() + 1);
    }
    setDates(week);
    setDateTitle(`${week[0].toDateString()} - ${week[6].toDateString()}`)
  }

  const BackDate = () => {
    switch (tab) {
      case 0:
        let preDate: Date = new Date(dates[0].setDate(dates[0].getDate() - 1));
        setDates([preDate]);
        setDateTitle(preDate.toDateString());
        break;
      case 1:
        let preWeek: Date = new Date(dates[0].setDate(dates[0].getDate() - 7));
        GetWeek(preWeek);
        break;
      case 2:
        GetMonth(dates[0].getMonth() === 0 ? 11 : (dates[0].getMonth() - 1), dates[0].getMonth() === 0 ? (dates[0].getFullYear() - 1) : dates[0].getFullYear());
        break;
    }
  }

  const ForwardDate = () => {
    switch (tab) {
      case 0:
        let nextDate: Date = new Date(dates[0].setDate(dates[0].getDate() + 1));
        setDates([nextDate]);
        setDateTitle(nextDate.toDateString());
        break;
      case 1:
        let nextWeek: Date = new Date(dates[0].setDate(dates[0].getDate() + 7));
        GetWeek(nextWeek);
        break;
      case 2:
        GetMonth(dates[0].getMonth() === 11 ? 0 : (dates[0].getMonth() + 1), dates[0].getMonth() === 11 ? (dates[0].getFullYear() + 1) : dates[0].getFullYear());
        break;
    }
  }

  const GetEmployees = () => {
    return Organisation.Employees.filter((e) => e.OnboardingComplete && e.Business.CurrentEmployment.EmploymentType === "ROTA BASED");
  }

  const GetDefaultDate = () => {
    switch (tab) {
      case 0:
        return `${dates[0].getFullYear()}-${(dates[0].getMonth() + 1) < 10 ? "0" : ""}${(dates[0].getMonth() + 1)}-${dates[0].getDate() < 10 ? "0" : ""}${dates[0].getDate()}`;
      default:
        return `${new Date().getFullYear()}-${(dates[0].getMonth() + 1) < 10 ? "0" : ""}${(dates[0].getMonth() + 1)}-${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}`;
    }
  }

  return (<>
    <Modal Show={show} Close={() => { setShow(false); setShift(defaultshift) }} Buttons={[{
      Text: "Cancel",
      OnClick: () => { setShow(false); setShift(defaultshift) },
      Color: "Link"
    }, {
      Text: "Save Shift",
      OnClick: () => SaveShift(shift),
      Color: "Theme"
    }]} Size={'small'} Title={'Shift'}>
      <p>Set the start and end times of the shift for the specified date that the employee is expected to work on</p>
      <Input Type="select" Value={shift.EmployeeID ? shift.EmployeeID.toString() : ""} OnChange={(v) => setShift({ ...shift, EmployeeID: v ? parseInt(v) : 0 })} Placeholder="..." Label="Employee" Options={Organisation.Employees.filter(e => e.OnboardingComplete).map(emp => {
        return {
          Value: `${emp.ID}`,
          Text: emp.ID ? `${emp.Personal.Title ? `${emp.Personal.Title}. ` : ""}${emp.Personal.Forename} ${emp.Personal.Surname}` : ""
        }
      })} />
      <Input Type='date' OnChange={(v) => setShift({ ...shift, Date: v })} Value={shift.Date} Placeholder='...' Label='Date' />
      <Input Type='time' Width="half" OnChange={(v) => setShift({ ...shift, StartTime: v })} Value={shift.StartTime} Placeholder='...' Label='Start Time' />
      <Input Type='time' Width="half" OnChange={(v) => setShift({ ...shift, EndTime: v })} Value={shift.EndTime} Placeholder='...' Label='End Time' />
    </Modal>

    <div className={general.Main}>
      <div className={general.Header}>
        <h1>
          <span>
            <i>
              <FontAwesomeIcon icon={faCalendarWeek} />
            </i>
            {Language.Applications.ShiftScheduling}
          </span>
          <small>Schedule your Employee's daily, weekly and monthly shifts using this tool. Edit your company's opening & closing times to see a filtered view of time to avoid scheduling employees on days you are not open</small>
        </h1>

        {tab === 2 ? <></> : <div className={styles.Filter}>
          <b>Filter Time:</b>
          <Input Width="half" Placeholder="..." Label="" OnChange={(v) => setMinTime(v)} Type={'time'} Value={minTime} />
          <b>
            -
          </b>
          <Input Width="half" Placeholder="..." Label="" OnChange={(v) => setMaxTime(v)} Type={'time'} Value={maxTime} />
        </div>}

        <div className={styles.Tabs}>
          <button className={new Date().toLocaleDateString(window.navigator.language) === dates[0].toLocaleDateString(window.navigator.language) ? styles.Active : ""} onClick={() => { setDates([new Date()]); setTab(0) }}>
            TODAY
          </button>
          <button className={tab === 0 && new Date().toLocaleDateString(window.navigator.language) !== dates[0].toLocaleDateString(window.navigator.language) ? styles.Active : ""} onClick={() => setTab(0)}>
            DAY
          </button>
          <button className={tab === 1 ? styles.Active : ""} onClick={() => setTab(1)}>
            WEEK
          </button>
          <button className={tab === 2 ? styles.Active : ""} onClick={() => setTab(2)}>
            MONTH
          </button>
        </div>

        <Button Type="button" OnClick={() => {
          setShow(true); setShift({
            ...defaultshift,
            Date: GetDefaultDate()
          })
        }} Color="Theme">
          <i>
            <FontAwesomeIcon icon={faPlus} />
          </i>
          Add Shift
        </Button>

        <div className={styles.Controls}>
          <button onClick={() => BackDate()}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>

          <p>{dateTitle}</p>

          <button onClick={() => ForwardDate()}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
        </div>
      </div>
      <div className={styles.MainInner}>
        {tab === 0 && dates.length === 1 ? <div className={styles.MainInnerDay}><DayView OpenModal={(s) => { setShow(true); setShift(s) }} Shifts={Organisation.Shifts} Min={minTime} Max={maxTime} Employees={GetEmployees()} OnClick={(Tab, Date) => { setTab(Tab); setDates([Date]) }} Dates={dates} /></div> : <></>}
        {tab === 1 && dates.length === 7 ? <WeekView OpenModal={(s) => { setShow(true); setShift(s) }} Shifts={Organisation.Shifts} Min={minTime} Max={maxTime} Employees={GetEmployees()} Dates={dates} /> : <></>}
        {tab === 2 && dates.length >= 28 ? <MonthView OpenModal={(s) => { setShow(true); setShift(s) }} Shifts={Organisation.Shifts} Employees={GetEmployees()} Dates={dates} OnClick={(Tab, Date) => { setTab(Tab); setDates([Date]) }} /> : <></>}
      </div>
    </div>
  </>);
};

export default PortalRota;

type Props = {
  Shifts: Shift[];
  Employees: Employee[];
  Dates: Date[];
  OnClick?: (Tab, Date) => void;
  Min?: string;
  Max?: string;
  OpenModal?: (Shift: Shift) => void;
}

const DayView: React.FC<Props> = ({ Employees, Dates, Min, Max, Shifts, OpenModal }) => {
  const { User } = useContext(UserContext);
  let minutes: string[] = ["00", "15", "30", "45"]
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const GetHours = () => {
    let time: string[] = [];

    for (var i = 0; i <= 23; i++) {
      time.push(`${(i < 10 ? "0" : "") + i}`)
    }

    return time;
  }

  let hours: string[] = GetHours();

  const GetWidth = (Shift: Shift) => {
    let startHH: number = parseInt(Shift.StartTime.split(":")[0]);
    let startMM: number = parseInt(Shift.StartTime.split(":")[1]);
    let endHH: number = parseInt(Shift.EndTime.split(":")[0]);
    let endMM: number = parseInt(Shift.EndTime.split(":")[1]);
    let width: string = `${((endHH + ((1 / 60) * endMM)) - (startHH + ((1 / 60) * startMM))) * 200}px`;

    return width;
  }

  const GetIsClosed = () => {
    const keys: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const index: number = Dates[0].getDay();

    if (User.OpeningTimes === "{}") {
      return false;
    } else {
      return User.OpeningTimesData[keys[index]] ? User.OpeningTimesData[keys[index]][User.OpeningTimesData.Sun.length - 1].isClosed : false;
    }
  }

  const GetLeft = (Shift: Shift) => {
    let startHH: number = parseInt((Min ? Min : "00:00").split(":")[0]);
    let endHH: number = parseInt(Shift.StartTime.split(":")[0]);
    let endMM: number = parseInt(Shift.StartTime.split(":")[1]);
    let width: string = `${((endHH + ((1 / 60) * endMM)) - (startHH)) * 200}px`;

    return width;
  }

  return (<>
    {GetIsClosed() ? <div className={styles.Closed}>
      <h1>
        Closed
      </h1>
      <p>This organisation is closed on a {days[Dates[0].getDay()]}</p>
    </div> : <>
      <div className={styles.HourHeader}>
        <div className={styles.HourEmployee}>

        </div>

        {hours.filter(h => (new Date(`2000-01-01T${h}:59:00`) >= new Date(`2000-01-01T${Min}:00`)) && (new Date(`2000-01-01T${h}:59:00`) <= new Date(`2000-01-01T${Max}:00`))).map((t) => {
          return <div className={styles.Hour}>
            <h2>{t}:00</h2>
            {
              minutes.map((m) => {
                return <p>{t}:{m}</p>
              })
            }
          </div>
        })
        }
      </div>
      {
        Employees.map((emp) => {
          return <div className={styles.HourRow}>
            <Link to={`/Portal/Employees/Employee/${EncryptValue(emp.ID.toString())}`} className={styles.HourEmployee}>
              <h2>{emp.Personal.Forename} {emp.Personal.Surname}
                <small>{emp.Business.CurrentEmployment.JobTitle ? emp.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}</small>
              </h2>
            </Link>

            {hours.filter(h => (new Date(`2000-01-01T${h}:59:00`) >= new Date(`2000-01-01T${Min}:00`)) && (new Date(`2000-01-01T${h}:59:00`) <= new Date(`2000-01-01T${Max}:00`))).map((t) => {
              return <div className={styles.HourRowInner}></div>
            })
            }

            <div className={styles.HourShifts}>
              {
                Shifts.filter((s) => s.EmployeeID === emp.ID && s.Date === `${Dates[0].getFullYear()}-${(Dates[0].getMonth() + 1) < 10 ? "0" : ""}${(Dates[0].getMonth() + 1)}-${Dates[0].getDate() < 10 ? "0" : ""}${Dates[0].getDate()}`).map((s) => {
                  return <div onClick={OpenModal ? () => OpenModal(s) : () => { }} style={{ width: GetWidth(s), marginLeft: GetLeft(s) }} className={styles.HourShiftsItem}>
                    <b>{s.StartTime} till {s.EndTime}</b>
                  </div>
                })
              }
            </div>
          </div>
        })
      }
    </>}
  </>)
}

const WeekView: React.FC<Props> = ({ Employees, Dates, Min, Max, Shifts, OpenModal, OnClick }) => {
  const { User } = useContext(UserContext);
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const GetHours = () => {
    let time: string[] = [];

    for (var i = 0; i <= 23; i++) {
      time.push(`${(i < 10 ? "0" : "") + i}`)
    }

    return time;
  }

  const hours: string[] = GetHours();

  const GetHeight = (Shift: Shift) => {
    let startHH: number = parseInt(Shift.StartTime.split(":")[0]);
    let startMM: number = parseInt(Shift.StartTime.split(":")[1]);
    let endHH: number = parseInt(Shift.EndTime.split(":")[0]);
    let endMM: number = parseInt(Shift.EndTime.split(":")[1]);
    let width: string = `${((endHH + ((1 / 60) * endMM)) - (startHH + ((1 / 60) * startMM))) * 50}px`;

    return width;
  }

  const GetTop = (Shift: Shift) => {
    let startHH: number = parseInt((Min ? Min : "00:00").split(":")[0]);
    let endHH: number = parseInt(Shift.StartTime.split(":")[0]);
    let endMM: number = parseInt(Shift.StartTime.split(":")[1]);
    let width: string = `${((endHH + ((1 / 60) * endMM)) - (startHH)) * 50}px`;

    return width;
  }

  const GetIsClosed = (Day: number) => {
    const keys: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const index: number = Day;

    if (User.OpeningTimes === "{}") {
      return false;
    } else {
      return User.OpeningTimesData[keys[index]] ? User.OpeningTimesData[keys[index]][User.OpeningTimesData.Sun.length - 1].isClosed : false;
    }
  }

  return (<>
    {days.map((d, i) => {
      return <div className={`${styles.Day} ${GetIsClosed(i) ? styles.DayClosed : ""} ${[0, 6].includes(i) ? styles.Weekend : ""}`} style={{ width: `${Employees.length * 50 + 70}px` }}>
        <h2 onClick={OnClick ? () => OnClick(0, d) : () => { }}>
          {d}
          <small>({Dates[i].toLocaleDateString(window.navigator.language)})</small>
        </h2>
        <div className={styles.DayEmployees}>
          {
            Employees.map((emp, ind) => {
              return <>
                <Link to={`/Portal/Employees/Employee/${EncryptValue(emp.ID.toString())}`}>{emp.Personal.Forename.split("")[0]}{emp.Personal.Surname.split("")[0]}</Link>

                <div className={styles.DayShifts}>
                  {
                    Shifts.filter((s) => s.EmployeeID === emp.ID && s.Date === `${Dates[i].getFullYear()}-${(Dates[i].getMonth() + 1) < 10 ? "0" : ""}${(Dates[i].getMonth() + 1)}-${Dates[i].getDate() < 10 ? "0" : ""}${Dates[i].getDate()}`).map((s) => {
                      return <div onClick={OpenModal ? () => OpenModal(s) : () => { }} style={{ height: GetHeight(s), marginTop: GetTop(s), left: `${(ind * 50)}px` }} className={styles.DayShiftsItem}>
                        <b>{s.StartTime} till {s.EndTime}</b>
                      </div>
                    })
                  }
                </div>
              </>
            })
          }
        </div>

        {
          hours.filter((h) => (new Date(`2000-01-01T${h}:00:00`) >= new Date(`2000-01-01T${Min}:00`)) && (new Date(`2000-01-01T${h}:00:00`) <= new Date(`2000-01-01T${Max}:00`))).map((h) => {
            return <p><span>{h}:00</span></p>
          })
        }
      </div>
    })
    }</>)
}

const MonthView: React.FC<Props> = ({ Employees, Dates, OnClick, Shifts, OpenModal }) => {
  const { User } = useContext(UserContext);
  const [dayList, setDayList] = useState(Dates);
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  useEffect(() => {
    var dates: Date[] = [] as Date[];
    let Year = Dates[0].getFullYear();
    let Month = Dates[0].getMonth();

    let firstDay: number = Dates[0].getDay();
    let lastDay: Date = new Date(Month === 11 ? (Year + 1) : Year, Month === 11 ? 0 : (Month + 1), 0);
    let startDay: Date = new Date(new Date(Year, Month, 1).setDate(-firstDay + 1));
    let finalDay: Date = new Date(lastDay.setDate(lastDay.getDate() + (6 - lastDay.getDay())));

    for (var d = startDay; d <= finalDay; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }

    setDayList(dates);
  }, [Dates])

  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((d - +yearStart) / 86400000) + 1) / 7);
    return weekNo;
  }

  const GetEmployee = (ID: number) => {
    let employee: Employee | undefined = Employees.find(e => e.ID === ID);
    if (employee)
      return `${employee.Personal.Forename} ${employee.Personal.Surname}`;
    return "";
  }

  const GetIsClosed = (Day: number) => {
    const keys: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const index: number = Day;

    if (User.OpeningTimes === "{}") {
      return false;
    } else {
      return User.OpeningTimesData[keys[index]] ? User.OpeningTimesData[keys[index]][User.OpeningTimesData.Sun.length - 1].isClosed : false;
    }
  }

  return (<>
    <div className={styles.MonthDay}>
      <h2>
        #
      </h2>
      {
        dayList.filter((_, i) => (i % 7 === 0)).map((date) => {
          return <div className={styles.MonthDayDate} onClick={OnClick ? () => OnClick(1, date) : () => { }}>
            <b>{getWeekNumber(date)}</b>
          </div>
        })
      }
    </div>

    {days.map((d, i) => {
      return <>
        <div className={`${styles.MonthDay} ${GetIsClosed(i) ? styles.MonthDayClosed : ""}`}>
          <h2>
            {d}
          </h2>

          {
            dayList.filter((date) => date.getDay() === i).map((date, ind) => {
              return <button type="button" className={`${styles.MonthDayDate} ${date.toLocaleDateString(window.navigator.language) === new Date().toLocaleDateString(window.navigator.language) ? styles.Active : ""} ${date.getMonth() !== Dates[0].getMonth() ? styles.Blank : ""}`} onClick={OnClick ? () => OnClick(0, date) : () => { }}>
                {date.getMonth() === Dates[0].getMonth() ? <span>{date.getDate()}</span> : <></>}

                <div className={styles.MonthDayShifts}>
                  {
                    Employees.filter((e) => Shifts.find((s) => s.EmployeeID === e.ID && s.Date === `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? "0" : ""}${(date.getMonth() + 1)}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`)).map((e) => {
                      return <>
                        <b>{GetEmployee(e.ID)}</b>
                        {
                          Shifts.filter((s) => s.EmployeeID === e.ID && s.Date === `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? "0" : ""}${(date.getMonth() + 1)}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`).map((s) => {
                            return <button type="button" onClick={OpenModal ? (e) => { e.stopPropagation(); OpenModal(s); } : () => { }}>{s.StartTime} - {s.EndTime}</button>
                          })
                        }
                      </>
                    })
                  }
                </div>
              </button>
            })
          }
        </div>
      </>
    })
    }</>)
}
