import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faChevronDown, faLink, faPoundSign, faPrint, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../../components/Banner/Banner';
import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../../components/Login/Login';

import UserProfile from '../../../../../components/User/User';
import { UserContext } from '../../../../../Context/UserContext';
import * as styles from './Redundancy-Pay-Calculator.module.scss';

interface Props {
  hideBanner?: boolean;
}

const RedundancyPayCalculator: React.FC<Props> = ({ hideBanner }) => {
  const { User } = useContext(UserContext);

  const [salary, setSalary] = useState<number>(0);
  const [years, setYears] = useState<number>(0);
  const [age, setAge] = useState<number>(0);
  const [checkEligibility, setCheckEligibility] = useState<boolean>(false);

  const maxEntitlement: number = 544;
  const maxServiceYears: number = 20;
  const maximumTotal: number = 16320;

  const PrintClasses = {
    "PrintTotal": {
      width: "calc(50% - 20px)",
      padding: "35px 25px 25px",
      margin: "10px",
      float: "left",
      backgroundColor: "#eee",
      borderRadius: "5px",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      boxSizing: "border-box"
    } as React.CSSProperties,
    "PrintTotalH1": {
      width: "100%",
      float: "left",
      textAlign: "center",
      margin: "0px 0px 10px 0px",
      color: "var(--PrimaryColor)",
      fontSize: "30px",
      fontWeight: "normal",
    } as React.CSSProperties,
    "PrintTotalText": {
      width: "100%",
      float: "left",
      textAlign: "center",
      margin: "0px 0px 5px 0px",
      color: "#333",
      fontSize: "15px"
    } as React.CSSProperties,
    "PrintDescription": {
      width: "100%",
      float: "left",
      textAlign: "center",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "10px",
      color: "#333",
      fontSize: "15px"
    } as React.CSSProperties,
    "PrintTitle": {
      fontWeight: "normal",
      width: "100%",
      float: "left",
      textAlign: "center",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "10px",
      fontSize: "25px"
    } as React.CSSProperties
  }

  const AdjustNumber = (Value: number) => {
    return (Math.round(Value * 100) / 100).toFixed(2)
  }

  const GetWeeks = () => {
    var minAge = age - GetServiceYears();
    var weeks = 0;

    for (var i = minAge; i < age; i++) {
      weeks += GetWeeksPerAge(i);
    }

    return weeks;
  }

  const GetWeeksEqualTo = (weeks: number) => {
    var minAge = age - GetServiceYears();
    var total = 0;

    for (var i = minAge; i < age; i++) {
      if (weeks === GetWeeksPerAge(i))
        total++;
    }

    return total;
  }

  const GetRedundancyPay = () => {
    var amount = (GetWeeklySalary() * GetWeeks());
    if (amount > maximumTotal)
      amount = maximumTotal;
    return amount;
  }

  const GetWeeklySalary = () => {
    var amount = salary / 52;
    if (amount > maxEntitlement)
      amount = maxEntitlement;
    return amount;
  }

  const GetWeeksPerAge = (ageAtTime: number) => {
    if (ageAtTime < 22)
      return .5;
    if (ageAtTime >= 22 && ageAtTime < 41)
      return 1;
    if (ageAtTime >= 41)
      return 1.5;
    return 0;
  }

  const GetServiceYears = () => {
    var difference = years;
    if (difference > maxServiceYears)
      difference = maxServiceYears;
    return difference;
  }

  const PrintResults = () => {
    var prtContent = document.getElementById("Results");
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    if (prtContent && WinPrint) {
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }
  }

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (<>
    {hideBanner ? <></> : <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Redundancy Pay Calculator</title><meta charSet='utf-8' />
      </Helmet>
      <Banner />
      <Breadcrumb Trail={[{
        Text: "Calculators",
        To: "/Calculators"
      }, {
        Text: "Redundancy Pay",
        To: ""
      }]} />
    </>}

    <div className={styles.Calculator}>
      <div className={styles.CalculatorInner}>
        <div className={styles.CalculatorMain}>
          <div className={styles.Info}>
            <p>
              <b>Calculate how much statutory redundancy you can get. It’s based on age, weekly pay and number of years in the job.</b>

              <b>Your weekly pay is the average you earned per week over the 12 weeks before the day you got your redundancy notice.</b>

              <b>You only qualify for statutory redundancy pay if you’ve worked for your employer for at least 2 years.</b>

              <ul>
                <li>0.5 week’s pay for each full year worked when you’re under 22</li>
                <li>1 week’s pay for each full year worked when you’re between 22 and 41</li>
                <li>1.5 week’s pay for each full year worked when you’re 41 or older</li>
              </ul>
            </p>
          </div>

          <div className={styles.Form}>
            <div className={`${styles.Input} ${styles.Full}`}>
              <label>Years with Employer</label>
              <small>Only count full years of service. For example, 3 years and 9 months count as 3 years.</small>
              <input type="number" disabled={checkEligibility} value={years} onChange={(e) => setYears(parseInt(e.target.value))} />
            </div>

            <div className={styles.Input}>
              <label>Age at Redundancy</label>
              <input type="number" min={years} disabled={checkEligibility} value={age} onChange={(e) => setAge(parseFloat(e.target.value))} />
            </div>

            <div className={styles.Input}>
              <label>Salary per year</label>
              <i>
                <FontAwesomeIcon icon={faPoundSign} />
              </i>
              <input type="number" disabled={checkEligibility} value={salary} onChange={(e) => setSalary(parseFloat(e.target.value))} />
            </div>

            {checkEligibility ? <div className={styles.Button}>
              <button type="button" onClick={() => { setCheckEligibility(false); setSalary(0); setAge(0); setYears(0) }}>Reset <i><FontAwesomeIcon icon={faSyncAlt} /></i></button>
            </div> : <div className={styles.Button}>
              <button type="button" onClick={() => { setCheckEligibility(true); }}>Check Eligibility <i><FontAwesomeIcon icon={faArrowRight} /></i></button>
            </div>}
          </div>

          {checkEligibility ? <div className={styles.Results}>
            {GetServiceYears() >= 2 ? <>
              <div className={styles.Total}>
                <h1>£{numberWithCommas(GetRedundancyPay())}</h1>
                <b>The employee is eligible for £{GetWeeklySalary()} per week</b>
                {GetWeeklySalary() === maxEntitlement ? <p>Statutory Pay is capped at £{maxEntitlement} per week</p> : <></>}
              </div>

              <div className={styles.Total}>
                <h1>{GetWeeks()} Weeks</h1>
                <b>Made up of:<br />
                  {GetWeeksEqualTo(.5) ? <>{GetWeeksEqualTo(.5) * 0.5} weeks before the employee turned 22<br /></> : ""}
                  {GetWeeksEqualTo(1) ? <>{GetWeeksEqualTo(1) * 1} weeks between the ages of 22 and 41<br /></> : ""}
                  {GetWeeksEqualTo(1.5) ? <>{GetWeeksEqualTo(1.5) * 1.5} weeks after the employee turned 41</> : ""}
                </b>
                {GetServiceYears() == maxServiceYears ? <p>Length of service is capped at {maxServiceYears} years</p> : <></>}
              </div>

              <div className={styles.PrintResults} id="Results">
                <h2 style={PrintClasses["PrintTitle"]}>Results of Redundancy Request</h2>
                <p style={PrintClasses["PrintDescription"]}>The following results are based on an employee being made redundant at the age of <b>{age}</b> years old after working for the employer for <b>{years}</b> years on a final salary of <b>£{numberWithCommas(salary)}</b> (or £{AdjustNumber(salary / 52)} per week).</p>

                <div style={PrintClasses["PrintTotal"]}>
                  <h1 style={PrintClasses["PrintTotalH1"]}>£{numberWithCommas(GetRedundancyPay())}</h1>
                  <b style={PrintClasses["PrintTotalText"]}>The employee is eligible for £{GetWeeklySalary()} per week</b>
                  {GetWeeklySalary() === maxEntitlement ? <p style={PrintClasses["PrintTotalText"]}>Statutory Pay is capped at £{maxEntitlement} per week</p> : <></>}
                </div>

                <div style={PrintClasses["PrintTotal"]}>
                  <h1 style={PrintClasses["PrintTotalH1"]}>{GetWeeks()} Weeks</h1>
                  <b style={PrintClasses["PrintTotalText"]}>Made up of:<br />
                    {GetWeeksEqualTo(.5) ? <>{GetWeeksEqualTo(.5) * 0.5} weeks (0.5 weeks per year) before the employee turned 22<br /></> : ""}
                    {GetWeeksEqualTo(1) ? <>{GetWeeksEqualTo(1) * 1} weeks (1 week per year) between the ages of 22 and 41<br /></> : ""}
                    {GetWeeksEqualTo(1.5) ? <>{GetWeeksEqualTo(1.5) * 1.5} weeks (1.5 weeks per year) after the employee turned 41</> : ""}
                  </b>
                  {GetServiceYears() == maxServiceYears ? <p style={PrintClasses["PrintTotalText"]}>Length of service is capped at {maxServiceYears} years</p> : <></>}
                </div>
              </div>

              <div className={styles.Print}>
                <button type="button" onClick={() => PrintResults()}><i><FontAwesomeIcon icon={faPrint} /></i> Print Results</button>
              </div>
            </> : <div className={`${styles.Eligible} ${styles.Red}`}>
              <h1>Not Eligible</h1>
              <p>You only qualify for statutory redundancy pay if you’ve worked for your employer for at least 2 years.</p>
            </div>}
          </div> : <></>}
        </div>
        <div className={styles.CalculatorSidebar}>
          {hideBanner ? <></> : !User.ID ? <LoginForm /> : <UserProfile />}

          <div className={styles.CalculatorSidebarInner}>
            <h1>References</h1>
            <i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/redundancy-your-rights/redundancy-pay">(GOV.uk) Redundancy Pay</a>
            <ul>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/redundancy-your-rights">(GOV.uk) Redundancy : Your Rights</a></li>
              <li>
                <i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/calculate-your-redundancy-pay">(GOV.uk) Calculate Your Redundancy Pay</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default RedundancyPayCalculator;
