// extracted by mini-css-extract-plugin
export var Button = "Redundancy-Pay-Calculator-module--Button--ba9fb";
export var Calculator = "Redundancy-Pay-Calculator-module--Calculator--68e17";
export var CalculatorInner = "Redundancy-Pay-Calculator-module--CalculatorInner--e8a93";
export var CalculatorMain = "Redundancy-Pay-Calculator-module--CalculatorMain--1f177";
export var CalculatorSidebar = "Redundancy-Pay-Calculator-module--CalculatorSidebar--86bdf";
export var CalculatorSidebarInner = "Redundancy-Pay-Calculator-module--CalculatorSidebarInner--55e32";
export var Checkbox = "Redundancy-Pay-Calculator-module--Checkbox--6c20a";
export var CheckboxInner = "Redundancy-Pay-Calculator-module--CheckboxInner--362a5";
export var CheckboxInnerSlider = "Redundancy-Pay-Calculator-module--CheckboxInnerSlider--5707f";
export var Eligible = "Redundancy-Pay-Calculator-module--Eligible--7de0d";
export var Form = "Redundancy-Pay-Calculator-module--Form--b2a64";
export var Full = "Redundancy-Pay-Calculator-module--Full--47e1e";
export var Green = "Redundancy-Pay-Calculator-module--Green--451be";
export var Info = "Redundancy-Pay-Calculator-module--Info--b9fc5";
export var Input = "Redundancy-Pay-Calculator-module--Input--cddc2";
export var Print = "Redundancy-Pay-Calculator-module--Print--bea0e";
export var PrintResults = "Redundancy-Pay-Calculator-module--PrintResults--90871";
export var Red = "Redundancy-Pay-Calculator-module--Red--4f83f";
export var Results = "Redundancy-Pay-Calculator-module--Results--c5096";
export var Total = "Redundancy-Pay-Calculator-module--Total--68c2b";