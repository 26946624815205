import * as styles from './User.module.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { EmployeeContext, UserContext } from '../../Context/UserContext';
import { User } from '../../Classes/User';
import { navigate } from 'gatsby';
import { Decrypt, parseJwt } from '../../Services/Crypto';
import { Employee } from '../../Classes/Employee';

const UserProfile: React.FC = () => {
  const { User, setUser } = useContext(UserContext);
  const { Employee, setEmployee } = useContext(EmployeeContext);
  const [profile, setProfile] = useState<User>({} as User);

  useEffect(() => {
    if (User.ID)
      setProfile(User);
  }, [User])

  useMemo(() => {
    if (typeof window !== 'undefined') {
      let token = localStorage.getItem("User");
      if (token) {
        let jwt = parseJwt(Decrypt(token));
        setProfile({
          ID: parseInt(jwt.nameid),
          isAdmin: jwt.role.includes("ADMIN") ? jwt.role.split(":")[1] === "True" : false,
          isEmployee: jwt.role.includes("EMPLOYEE"),
          isConsultant: jwt.role.includes("CONSULTANT"),
          Email: jwt.email,
          Name: jwt.unique_name
        } as User)
      }
    }
  }, [])

  const LogOut = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("sidebar1");
    localStorage.removeItem("sidebar2");
    setEmployee({} as Employee);
    setUser({} as User);

    if (window.location.pathname.toLowerCase().includes("/employee/portal/"))
      return navigate("/Login/Employee");

    if (window.location.pathname.toLowerCase().includes("/portal/"))
      return navigate("/Login/Corporate");

    if (window.location.pathname.toLowerCase().includes("/admin/"))
      return navigate("/Login");
  }

  return (<>
    {profile.ID ? <div className={styles.User}>
      <div className={styles.UserImage}>
        <h2 style={{ backgroundImage: `url(${User.isEmployee && Employee.ID ? Employee.Personal.Image : User.Logo})` }}>{(User.isEmployee && Employee.ID && Employee.Personal.Image) || (!User.isEmployee && User.Logo) ? <></> : profile.isAdmin ? <i><FontAwesomeIcon icon={faUserShield} /></i> : `${profile.Name.includes(". ") ? profile.Name.split(". ")[1] ? profile.Name.split(". ")[1].split("")[0] : "" : profile.Name.split("")[0]}`}</h2>
      </div>
      <div className={styles.UserText}>
        <h1>{profile.isAdmin ? `ADMIN : ` : ``}{profile.Name}</h1>
        <a title="Sign Out" onClick={() => LogOut()}>Sign Out
          <FontAwesomeIcon icon={faSignOutAlt} />
        </a>
      </div>
    </div> : <></>}
  </>);
};

export default UserProfile;
