import { faCalendar, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faInfoCircle, faLink, faPrint, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BankHolidays, HolidayEvent } from '../../../../../Classes/Employee';
import Banner from '../../../../../components/Banner/Banner';
import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';
import { Loading } from '../../../../../components/Loading/Loading';
import LoginForm from '../../../../../components/Login/Login';

import UserProfile from '../../../../../components/User/User';
import { UserContext } from '../../../../../Context/UserContext';
import * as styles from './Annuel-Leave-Calculator.module.scss';

interface Props {
  hideBanner?: boolean;
}

const AnnualLeaveCalculator: React.FC<Props> = ({ hideBanner }) => {
  const { User } = useContext(UserContext);
  const [daysWorked, setDaysWorked] = useState<number[]>([] as number[]);
  const [employeeStart, setEmployeeStart] = useState<string>("");
  const [includeBankHolidays, setIncludeBankHolidays] = useState<boolean>(false);
  const [checkResults, setCheckResults] = useState<boolean>(false);
  const [bankHolidays, setBankHolidays] = useState<BankHolidays[]>([] as BankHolidays[]);
  const dayList: number[] = [0, 1, 2, 3, 4, 5, 6];
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const entitlement: number = 5.6;
  const maxEntitlement: number = 28;

  const PrintClasses = {
    "PrintTotal": {
      width: "calc(50% - 20px)",
      padding: "35px 25px 25px",
      margin: "10px",
      float: "left",
      backgroundColor: "#eee",
      borderRadius: "5px",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      boxSizing: "border-box"
    } as React.CSSProperties,
    "PrintTotalH1": {
      width: "100%",
      float: "left",
      textAlign: "center",
      margin: "0px 0px 10px 0px",
      color: "var(--PrimaryColor)",
      fontSize: "30px",
      fontWeight: "normal",
    } as React.CSSProperties,
    "PrintTotalText": {
      width: "100%",
      float: "left",
      textAlign: "center",
      margin: "0px 0px 5px 0px",
      color: "#333",
      fontSize: "15px"
    } as React.CSSProperties,
    "PrintDescription": {
      width: "100%",
      float: "left",
      textAlign: "left",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "5px",
      color: "#333",
      fontSize: "15px"
    } as React.CSSProperties,
    "PrintTitle": {
      fontWeight: "normal",
      width: "100%",
      float: "left",
      textAlign: "center",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "10px",
      fontSize: "25px"
    } as React.CSSProperties,
    "PrintList": {
      fontWeight: "normal",
      width: "100%",
      float: "left",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "10px",
      padding: "0px 0px 0px 30px",
      fontSize: "15px"
    } as React.CSSProperties
  }

  useEffect(() => {
    GetBankHolidays();
  }, [])

  const PrintResults = () => {
    var prtContent = document.getElementById("Results");
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    if (prtContent && WinPrint) {
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }
  }

  const GetBankHolidays = async () => {
    let holidays: BankHolidays[] = [] as BankHolidays[];

    await fetch("https://www.gov.uk/bank-holidays.json")
      .then((res) => res.json()).then((res) => {
        res["england-and-wales"]["events"].map((event: HolidayEvent) => {
          let index: number = holidays.findIndex(h => h.Year === new Date(event.date).getFullYear());
          if (index >= 0) {
            holidays[index].Days.push(event);
          } else {
            holidays.push({ Year: new Date(event.date).getFullYear(), Days: [event] as HolidayEvent[] } as BankHolidays);
          }
        });
      });

    setBankHolidays(holidays);
  }

  const ToggleDay = (day: number) => {
    let worked: number[] = JSON.parse(JSON.stringify(daysWorked));
    let d: number | undefined = worked.findIndex(n => n === day);
    if (d >= 0) {
      worked.splice(d, 1);
    } else {
      worked.push(day);
    }

    setDaysWorked(worked);
  }

  const DaysRemaining = (from: string) => {
    var startDate = new Date(from);
    var endOfYear = new Date(`${startDate.getFullYear() + 1}-01-01`);
    var daysOfYear: Date[] = [];
    for (var d = startDate; d <= endOfYear; d.setDate(d.getDate() + 1)) {
      var date = new Date(d);
      if (daysWorked.includes(date.getDay()))
        daysOfYear.push(date);
    }

    return Math.round(daysOfYear.length * 2) / 2;
  }

  const RemainingHolidays = (from: string) => {
    let perYear = daysWorked.length * entitlement;
    perYear = perYear > maxEntitlement ? maxEntitlement : perYear;
    let remaining = (perYear / 26) * (DaysRemaining(from) / (daysWorked.length * 2));
    remaining = remaining > maxEntitlement ? maxEntitlement : remaining;

    return Math.round((remaining - (includeBankHolidays ? GetWorkedBankHolidays(from).length : 0)) * 10) / 10;
  }

  const GetWorkedBankHolidays = (from: string) => {
    let holidays = bankHolidays.find(d => d.Year === new Date(from).getFullYear());
    if (holidays) {
      return holidays.Days.filter(day => daysWorked.includes(new Date(day.date).getDay()) && (new Date(day.date) >= new Date(from)))
    }

    return [];
  }

  return (<>
    {hideBanner ? <></> : <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Annual Leave Calculator</title><meta charSet='utf-8' />
      </Helmet>
      <Banner />
      <Breadcrumb Trail={[{
        Text: "Calculators",
        To: "/Calculators"
      }, {
        Text: "Annual Leave",
        To: ""
      }]} />
    </>}

    <div className={styles.Calculator}>
      <div className={styles.CalculatorInner}>
        <div className={styles.CalculatorMain}>
          <div className={styles.Info}>
            <p>
              <b>Almost all workers are legally entitled to 5.6 weeks’ paid holiday a year (known as statutory leave entitlement or annual leave).</b>

              This includes:

              <ul>
                <li>agency workers</li>
                <li>workers with irregular hours</li>
                <li>workers on zero-hours contracts</li>
              </ul>

              <b>Statutory paid holiday entitlement is limited to 28 days. For example, staff working 6 days a week are only entitled to 28 days’ paid holiday.</b>
              <b>An employer can include bank holidays as part of statutory annual leave.</b>
            </p>
          </div>

          <div className={styles.Form}>
            <div className={`${styles.Input} ${styles.Full}`}>
              <label>Employee Start Date</label>
              <i>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </i>
              <input disabled={checkResults} type="date"
                // min={`${bankHolidays[0].Year}-01-01`} 
                // max={`${bankHolidays[bankHolidays.length - 1].Year}-12-31`} value={employeeStart} 
                onChange={(e) => setEmployeeStart(e.target.value)} />
              <i>
                <FontAwesomeIcon icon={faCalendar} />
              </i>
            </div>

            <div className={`${styles.Input} ${styles.Full}`}>
              <label>Employee Work Days</label>
            </div>
            {
              dayList.map(d => {
                return <div className={styles.Checkbox}>
                  <span>{days[d]}</span>
                  <label className={styles.CheckboxInner}>
                    <span>No</span>
                    <input type="checkbox" checked={daysWorked.find(n => n === d) != null} onChange={(e) => ToggleDay(d)} disabled={checkResults} />
                    <div className={styles.CheckboxInnerSlider}></div>
                    <span>Yes</span>
                  </label>
                </div>
              })
            }

            <div className={`${styles.Checkbox} ${styles.Full}`}>
              <span>Include bank holidays as part of the Statutory Annual Leave?</span>
              <label className={styles.CheckboxInner}>
                <span>No</span>
                <input type="checkbox" checked={includeBankHolidays} onChange={(e) => setIncludeBankHolidays(e.target.checked)} disabled={checkResults} />
                <div className={styles.CheckboxInnerSlider}></div>
                <span>Yes</span>
              </label>
            </div>

            <div className={styles.Button}>
              {!checkResults ?
                <button type="button" disabled={!employeeStart || !daysWorked.length} onClick={() => setCheckResults(true)}>Calculate <i><FontAwesomeIcon icon={faArrowRight} /></i></button>
                : <button type="button" onClick={() => { setCheckResults(false); setDaysWorked([] as number[]); setEmployeeStart(""); setIncludeBankHolidays(false) }}>Reset <i><FontAwesomeIcon icon={faSyncAlt} /></i></button>
              }
            </div>
          </div>

          {checkResults ? <>
            <div className={styles.Results}>
              <div className={styles.Total}>
                <h1>{RemainingHolidays(`${new Date(employeeStart).getFullYear()}-01-01`) + GetWorkedBankHolidays(`${new Date(employeeStart).getFullYear()}-01-01`).length} Days</h1>
                <b>To Accrue In the Year of {`${new Date().getFullYear()}`}</b>
                <p>Made up of {RemainingHolidays(`${new Date(employeeStart).getFullYear()}-01-01`)} Accrued Days and {GetWorkedBankHolidays(`${new Date(employeeStart).getFullYear()}-01-01`).length} Bank Holidays</p>
              </div>

              <div className={styles.Total}>
                <h1>{RemainingHolidays(employeeStart) + GetWorkedBankHolidays(employeeStart).length} Days</h1>
                <b>To Accrue In the Remaining {DaysRemaining(employeeStart)} Work Days</b>
                <p>Made up of {RemainingHolidays(employeeStart)} Accrued Days and {GetWorkedBankHolidays(employeeStart).length} Bank Holidays</p>
              </div>
            </div>

            <div className={styles.PrintResults} id="Results">
              <h2 style={PrintClasses["PrintTitle"]}>Results of Annual Holiday Request</h2>
              <p style={PrintClasses["PrintDescription"]}>These results are based on the employee starting <b>{new Date(employeeStart).getFullYear()}</b> on <b>{new Date(employeeStart).toDateString().replace(new Date(employeeStart).getFullYear().toString() + " ", "")}</b>, and having an average working week where the following days are worked:</p>

              <ul style={PrintClasses["PrintList"]}>
                {daysWorked.sort().map(d => { return <li>{days[d]}</li> })}
              </ul>

              <p style={PrintClasses["PrintDescription"]}>Due to this, the employee is affected by the following Bank Holidays:</p>

              <ul style={PrintClasses["PrintList"]}>
                {GetWorkedBankHolidays(employeeStart).map(d => { return <li>{d.title}</li> })}
              </ul>

              <div style={PrintClasses["PrintTotal"]}>
                <h1 style={PrintClasses["PrintTotalH1"]}>{RemainingHolidays(`${new Date(employeeStart).getFullYear()}-01-01`) + GetWorkedBankHolidays(`${new Date(employeeStart).getFullYear()}-01-01`).length} Days</h1>
                <b style={PrintClasses["PrintTotalText"]}>To Accrue In the Year of {`${new Date().getFullYear()}`}</b>
                <p style={PrintClasses["PrintTotalText"]}>Made up of {RemainingHolidays(`${new Date(employeeStart).getFullYear()}-01-01`)} Accrued Days and {GetWorkedBankHolidays(`${new Date(employeeStart).getFullYear()}-01-01`).length} Bank Holidays</p>
              </div>

              <div style={PrintClasses["PrintTotal"]}>
                <h1 style={PrintClasses["PrintTotalH1"]}>{RemainingHolidays(employeeStart) + GetWorkedBankHolidays(employeeStart).length} Days</h1>
                <b style={PrintClasses["PrintTotalText"]}>To Accrue In the Remaining {DaysRemaining(employeeStart)} Work Days</b>
                <p style={PrintClasses["PrintTotalText"]}>Made up of {RemainingHolidays(employeeStart)} Accrued Days and {GetWorkedBankHolidays(employeeStart).length} Bank Holidays</p>
              </div>
            </div>

            <div className={styles.Print}>
              <button type="button" onClick={() => PrintResults()}><i><FontAwesomeIcon icon={faPrint} /></i> Print Results</button>
            </div>
          </> : <></>}
        </div>
        <div className={styles.CalculatorSidebar}>
          {hideBanner ? <></> : !User.ID ? <LoginForm /> : <UserProfile />}

          <div className={styles.CalculatorSidebarInner}>
            <h1>References</h1>
            <i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights">(GOV.uk) Holiday Entitlement</a>
            <ul>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights/calculate-leave-entitlement">(GOV.uk) Calculate Leave Entitlement</a></li>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights/holiday-pay-the-basics">(GOV.uk) Holiday Pay</a></li>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights/taking-holiday-before-leaving-a-job">(GOV.uk) Taking Holiday Before Leaving a Job</a></li>
            </ul>
          </div>

          {checkResults && GetWorkedBankHolidays(employeeStart).length ? <div className={`${styles.CalculatorSidebarInner} ${styles.Green}`}>
            <h1>Bank Holidays That Affect This Employee ({new Date(employeeStart).getFullYear()})</h1>
            <ul>
              {
                GetWorkedBankHolidays(employeeStart).map(day => {
                  return <li><b>{day.title}</b><p>{new Date(day.date).toDateString().replace(` ${new Date(day.date).getFullYear()}`, "")}</p></li>
                })
              }
            </ul>
          </div> : <></>}
        </div>
      </div>
    </div>
  </>);
};

export default AnnualLeaveCalculator;
