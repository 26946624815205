import { faBriefcase, faBriefcaseMedical, faCalculator, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import * as styles from './Tools.module.scss';
import * as general from '../Employees/Styles.module.scss';
import { UserContext } from '../../../Context/UserContext';

const Tools: React.FC = () => {
    const { Language } = useContext(UserContext);

    return (<>
        <div className={general.Main}>
            <div className={general.Header}>
                <h1>
                    <span>
                        <i>
                            <FontAwesomeIcon icon={faBriefcase} />
                        </i>
                        {Language.Applications.ToolsWorkflows}
                    </span>
                    <small>Our various HR {Language.Applications.ToolsWorkflows} are designed to help you manage some key elements of your business which are sometimes a second thought when you are starting off in your HR Journey.</small>
                </h1>
            </div>

            <div className={general.MainInner}>
                <div className={styles.Calculators}>
                    <div className={styles.CalculatorsInner}>
                        <div className={`${styles.Card} ${styles.Disabled}`}>
                            <div className={styles.Banner}>
                                Coming Soon
                            </div>
                            <div className={styles.CardInner}>
                                <i>
                                    <FontAwesomeIcon icon={faBriefcaseMedical} />
                                </i>

                                <h1>Health & Safety Tools</h1>

                                <p>A simpe set of tools that help work through various Health & Safety steps of your business such as <b>Risk Assessment</b> and <b>Accident Logging</b></p>

                                <Link to={"/Portal/Tools/Health-and-Safety"}>
                                    Health & Safety Tools
                                </Link>
                            </div>
                        </div>

                        <div className={styles.Card}>
                            <i>
                                <FontAwesomeIcon icon={faCodeBranch} />
                            </i>

                            <h1>{Language.Applications.Workflows}</h1>

                            <p>A simpe set of tools that help work through various HR steps of your business such as <b>Recruitment</b> and <b>{Language.Applications.OnboardingWorkflow}</b></p>

                            <Link to={"/Portal/Tools/Workflows"}>
                                Workflow Tools
                            </Link>
                        </div>

                        <div className={styles.Card}>
                            <i>
                                <FontAwesomeIcon icon={faCalculator} />
                            </i>

                            <h1>Calculators</h1>

                            <p>Our simple to use Statutory Calculators for <b>Annual Leave Pay</b>
                                , <b>Redundancy Pay</b>
                                and <b>Maternity Pay</b> entitlemment and eligibility.</p>

                            <Link to={"/Portal/Tools/Calculators"}>
                                Calculators
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default Tools;
