import { faAddressCard, faSitemap, faUserAltSlash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import * as styles from './Workflows.module.scss';
import * as general from '../../Employees/Styles.module.scss';
import { UserContext } from '../../../../Context/UserContext';

const Workflows: React.FC = () => {
    const { Language } = useContext(UserContext);

    return (<div className={general.Main}>
        <div className={general.Header}>
            <h1>
                <span>
                    <i>
                        <FontAwesomeIcon icon={faSitemap} />
                    </i>
                    {Language.Applications.Workflows}
                </span>
                <small>Our {Language.Applications.Workflows} are here to help you work through the various processes that you may need to work through during your HR Journey in your business.</small>
            </h1>
        </div>

        <div className={general.MainInner}>
            <div className={styles.Calculators}>
                <div className={styles.CalculatorsInner}>
                    <div className={styles.Card}>
                        <i>
                            <FontAwesomeIcon icon={faUserPlus} />
                        </i>

                        <h1>{Language.Applications.RecruitmentWorkflow}</h1>

                        <p>A simple Workflow tool to take you through the <b>Recruitment Process</b>, from posting a job advert to inviting to interview.</p>

                        <Link to={"/Portal/Tools/Workflows/Recruitment"}>
                            Use Workflow
                        </Link>
                    </div>

                    <div className={styles.Card}>
                        <i>
                            <FontAwesomeIcon icon={faAddressCard} />
                        </i>

                        <h1>{Language.Applications.OnboardingWorkflow}</h1>

                        <p>A simple Workflow tool to take you through the <b>{Language.Applications.OnboardingWorkflow} Process</b> for a new starter.</p>

                        <Link to={"/Portal/Tools/Workflows/Onboarding"}>
                            Use Workflow
                        </Link>
                    </div>

                    <div className={styles.Card}>
                        <i>
                            <FontAwesomeIcon icon={faUserAltSlash} />
                        </i>

                        <h1>{Language.Applications.OffboardingWorkflow}</h1>

                        <p>A simple Workflow tool to take you through the <b>Offboarding Process</b> for a exiting an Employee.</p>

                        <Link to={"/Portal/Tools/Workflows/Offboarding"}>
                            Use Workflow
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

export default Workflows;
